import { useContext, useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Stack } from "@mui/material";
import { getProductFirebaseImageUrl } from "../../../services/API";
import Search from "../Search";
import { getIdNumber } from "../../../helpers/shopify";
import TextLink from "../../../components/TextLink";
import { Image as ImageComp } from "../../../components";
import { getUserById } from "../../../services/Firebase";
import { format } from "date-fns";
import Dropdown from "../../../components/Form/Dropdown";
import AdminContext from "../../../state/admin";
import { Header } from "../../../components/Text";
import { getMonthsDate, getNextMonthsDate } from "../../../helpers/time";
import ProductCard from "../../../components/ProductCard";
import { Container, Grid } from "../../../components/Layout";
import { Loader } from "../../../components/Loader";
import useLoadMoreOnScroll from "../../../hooks/useLoadMoreOnScroll";
import { last12Months } from "../../../state/constants";
import {
  AdminProductFragment,
  ProductSortKeys,
  ProductStatus,
  useGetAdminProductsLazyQuery,
  useGetAdminProductsQuery,
} from "../../../generated/graphql";
import { User } from "../../../types/user";
import { appendArtistsToProducts } from "../../../helpers/product";
import ScrollCarousel from "../../../components/ScrollCarousel";
import { Frame } from "../../../types/product";
import Button from "../../../components/Button";

type Row = {
  id: string;
  title: string;
  firebaseUrl: string;
};

type AdminProductWithArtist = AdminProductFragment & { artist: User | null };

const Products = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const { month, setMonth } = useContext(AdminContext);
  const [nextMonthsProducts, setNextMonthsProducts] = useState<AdminProductWithArtist[]>([]);
  const [nextMonthsPicksProducts, setNextMonthsPicksProducts] = useState<AdminProductWithArtist[]>([]);
  const [searchedProducts, setSearchedProducts] = useState<AdminProductFragment[]>();
  const [rows, setRows] = useState<Row[]>([]);
  const [getAdminProducts] = useGetAdminProductsLazyQuery();
  const {
    data,
    loading: loadingNextMonths,
    fetchMore,
    refetch,
  } = useGetAdminProductsQuery({
    variables: {
      query: `product_type:${getNextMonthsDate()} status:${ProductStatus.Active}`,
      sortKey: ProductSortKeys.CreatedAt,
      reverse: true,
      limit: 24,
    },
  });
  const { data: nextMonthsPicks } = useGetAdminProductsQuery({
    variables: {
      query: `product_type:${getNextMonthsDate()} tag:pick`,
      limit: 24,
    },
  });
  const afterCursor = data?.products?.pageInfo?.endCursor;
  const hasMore = data?.products?.pageInfo?.hasNextPage || false;

  const loadMore = async () => {
    fetchMore({
      variables: {
        afterCursor,
      },
    });
  };

  useLoadMoreOnScroll(ref, loadMore, hasMore, loadingNextMonths);

  const handleSearch = debounce((event: any) => {
    setSearchQuery(event.target.value);
  }, 1000);

  const columns: GridColDef[] = [
    {
      field: "image",
      headerName: "Image",
      width: 150,
      renderCell: (params) => <ImageComp height="50px" width="auto" src={params.row.image} />,
    },
    {
      field: "title",
      headerName: "Title",
      width: 150,
    },
    {
      field: "artist",
      headerName: "Artist",
      width: 150,
    },
    {
      field: "month",
      headerName: "Month",
      width: 150,
    },
    {
      field: "firebaseUrl",
      headerName: "Firebase Url",
      width: 200,
      renderCell: (params) =>
        params.row.firebaseUrl && (
          <TextLink openInNewTab href={`${params.row.firebaseUrl}`}>
            Open
          </TextLink>
        ),
    },
  ];

  const searchProducts = async (search: string) => {
    setLoading(true);
    const { data } = await getAdminProducts({
      variables: {
        query: search || `product_type:${getMonthsDate(month)}`,
        limit: 20,
      },
    });
    if (data?.products?.nodes) setSearchedProducts(data.products.nodes);
  };

  const getRows = async (products: AdminProductFragment[]) => {
    const productPromises = products.map(async ({ id, title, vendor, images, productType }) => {
      const artist = vendor ? await getUserById(vendor) : null;
      const firebaseUrl = await getProductFirebaseImageUrl(getIdNumber(id));
      const image = images.nodes?.[0]?.src;
      const date = productType ? new Date(productType) : new Date();
      const month = format(date, "MMMM yyyy");
      return {
        id,
        title,
        image,
        month,
        artist: `${artist?.firstName} ${artist?.lastName}`,
        firebaseUrl,
      };
    });

    const rowsToBeSet = await Promise.all(productPromises);
    setRows(rowsToBeSet);
    setLoading(false);
  };

  useEffect(() => {
    const getProductsArtists = async (products: AdminProductFragment[]) => {
      const productsWithArtist = await appendArtistsToProducts(products);
      setNextMonthsProducts(productsWithArtist);
    };
    if (data?.products?.nodes) {
      getProductsArtists(data.products.nodes);
    }
  }, [data]);

  useEffect(() => {
    const getProductsArtists = async (products: AdminProductFragment[]) => {
      const productsWithArtist = await appendArtistsToProducts(products);
      setNextMonthsPicksProducts(productsWithArtist);
    };
    if (nextMonthsPicks?.products?.nodes) {
      getProductsArtists(nextMonthsPicks.products.nodes);
    }
  }, [nextMonthsPicks]);

  useEffect(() => {
    if (month) {
      searchProducts(searchQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, month]);

  useEffect(() => {
    if (searchedProducts) {
      getRows(searchedProducts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedProducts]);

  return (
    <Stack gap={5} paddingY={3}>
      <Box>
        <Stack direction="row" gap={2}>
          <Search handleSearch={handleSearch} />
          <Dropdown
            options={last12Months}
            value={last12Months.find((m) => m.value === month)}
            onChange={({ value }) => setMonth(value)}
            width="auto"
            style={{ zIndex: 5 }}
          />
        </Stack>
        <DataGrid loading={loading} rows={rows} columns={columns} autoHeight />
      </Box>
      <Box>
        <ScrollCarousel
          header="Next Month's Picks"
          cta={{
            link: "/shop?collection=true",
            text: "Shop all",
          }}
        >
          {nextMonthsPicksProducts?.map((product) => (
            <ProductCard product={product} artist={product.artist} key={product.id} frame={Frame.Unframed} />
          )) || []}
        </ScrollCarousel>
      </Box>
      <Box paddingY={2} ref={ref}>
        <Stack gap={2}>
          <Header type="h3" align="center">
            Next Month's Collection
          </Header>
          <Container padding="0 0 72px">
            <Grid gap={24} rowGap={72}>
              {nextMonthsProducts.map((product) => (
                <ProductCard
                  key={product.id}
                  product={product}
                  artist={product.artist}
                  frame={Frame.Unframed}
                  refetch={refetch}
                />
              ))}
            </Grid>
            {loadingNextMonths ? (
              <Loader />
            ) : hasMore ? (
              <Stack width="100%" alignItems="center" padding={5}>
                <Button onClick={loadMore}>Load more</Button>
              </Stack>
            ) : null}
          </Container>
        </Stack>
      </Box>
    </Stack>
  );
};

export default Products;
