import styled from "styled-components";
import { Container } from "../Layout";
import { colors } from "../../theme";
import { media } from "../../helpers/layout";

export const Wrapper = styled(Container)<{
  imageLoaded: boolean;
  mounted: boolean;
  shadow?: boolean;
  fixedWidth?: number;
  fixedHeight?: number;
}>`
  background-color: ${({ imageLoaded, mounted }) => (imageLoaded && mounted ? colors.snowWhite : "transparent")};
  z-index: 0;
  ${(p) => p.fixedHeight && `height: ${p.fixedHeight}px;`}
  ${(p) => p.fixedWidth && `width: ${p.fixedWidth}px;`}
  ${(p) =>
    p.shadow &&
    `
    box-shadow: 3px 3px 3px ${colors.black}25;

    @media ${media.m} {
      box-shadow: 1px 1px 3px ${colors.black}25;
    }
  `};
`;
