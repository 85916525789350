import { DialogContent, Stack, Typography } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import Button from "../../components/Button";
import { useContext, useState } from "react";
import { Wishlist } from "../../types/wishlist";
import AuthContext, { Customer } from "../../state/auth";
import { useNavigate } from "react-router-dom";
import { deleteWishlist } from "../../services/Firebase/wishlists";
import { colors } from "../../theme";

type Props = {
  open: boolean;
  onClose: () => void;
  wishlist: Wishlist;
  customer: Customer;
};

const DeleteWishlistModal = ({ open, onClose, customer, wishlist }: Props) => {
  const { refetchCustomer } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onDelete = async () => {
    setLoading(true);
    if (wishlist.id && customer && customer.id === wishlist.ownerId) {
      await deleteWishlist(wishlist.id);
      navigate("/wishlist");
    }
    refetchCustomer();
    setLoading(false);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <CloseRounded
        onClick={onClose}
        fontSize="small"
        sx={{ cursor: "pointer", position: "absolute", top: 0, right: 0, margin: 2 }}
      />
      <DialogContent>
        <Typography variant="h2" marginBottom={1}>
          Are you sure?
        </Typography>
        <Stack gap={2}>
          <Typography color={colors.grey60}>Are you sure you want to delete {wishlist.name}?</Typography>
          <Stack gap={2} direction="row">
            <Button onClick={onClose} fullWidth secondary>
              Cancel
            </Button>
            <Button onClick={onDelete} fullWidth loading={loading}>
              Yes, delete
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteWishlistModal;
