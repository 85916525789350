import GalleryWallBuilder from "../../components/GalleryWallBuilder";

const GalleryWallBuilderPage = () => {
  const redirectToHome = () => {
    window.location.href = "/";
  };

  return <GalleryWallBuilder drawerIsOpen toggleDrawerIsOpen={redirectToHome} />;
};
export default GalleryWallBuilderPage;
