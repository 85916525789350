import { Stack } from "@mui/material";
import { BadgeButton } from "../../pages/Shop/styles";
import { Frame, frames } from "../../types/product";
import FrameBadge from "./FrameBadge";

type Props = {
  selectedFrame: Frame;
  setSelectedFrame: (frame: Frame) => void;
  size?: "large" | "small";
};

const FrameBadges = ({ selectedFrame, setSelectedFrame, size = "small" }: Props) => {
  const orderedFrames = [...frames].reverse();
  return (
    <Stack direction="row" gap={1.25} width="min-content">
      {orderedFrames.map((frame) => (
        <BadgeButton key={frame} selected={selectedFrame === frame} onClick={() => setSelectedFrame(frame)} size={size}>
          <FrameBadge frame={frame} size={size === "small" ? 22 : 32} />
        </BadgeButton>
      ))}
    </Stack>
  );
};

export default FrameBadges;
