import styled from "styled-components";
import { colors } from "../../theme";

export type Props = {
  name: string;
  id: string;
  value: string;
  label: string;
  disabled?: boolean;
  checked?: boolean;
};

const RadioButtonWrapper = styled.div`
  position: relative;
  display: flex;
`;

const StyledInput = styled.input<Partial<Props>>`
  padding: 10px 16px;
  height: 42px;
  border-radius: 100px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  display: block;
  opacity: 0;
  margin: 0;
  position: absolute;
  width: 100%;

  &:checked ~ .styled-radio {
    color: ${colors.white};
    background-color: ${colors.black};
    border: 1px solid ${colors.black};
  }

  &:hover ~ .styled-radio::after {
    border-color: ${colors.grey80};
  }
`;

const StyledRadioButton = styled.span<Partial<Props>>`
  position: relative;
  display: flex;
  justify-content: center;
  pointer-events: none;
  height: 42px;
  min-width: 54px;
  padding: 12px;
  color: ${(p) => (p.disabled ? colors.white : colors.black)};
  background-color: ${(p) => (p.disabled ? colors.grey10 : colors.cream)};
  border: 1px solid ${(p) => (p.disabled ? colors.grey02 : colors.grey10)};
  border-radius: 8px;
  font-size: 14px;
  line-height: 1.15;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    border: 2px solid transparent;
  }
`;

const RadioButton = ({ name, label, value, checked, disabled }: Props) => {
  return (
    <RadioButtonWrapper>
      <StyledInput type="radio" id={value} name={name} value={value} checked={checked} disabled={disabled} readOnly />
      <StyledRadioButton className="styled-radio" disabled={disabled}>
        {label}
      </StyledRadioButton>
    </RadioButtonWrapper>
  );
};

export default RadioButton;
