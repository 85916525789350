import { useToggle } from "react-use";
import { Link } from "react-router-dom";
import { Box, Drawer, IconButton, Stack } from "@mui/material";
import { BookmarkBorderOutlined, CloseRounded, MenuRounded, SearchRounded } from "@mui/icons-material";
import { User } from "../../types/user";
import { colors } from "../../theme";
import { Icon } from "../Icon";
import { NavItem } from "./styles";
import BasketNavItem from "./BasketNavItem";
import CountrySelector from "./CountrySelector";
import { BasketFragment } from "../../generated/storefront";
import Account from "./Account";

type Props = {
  user: User | null;
  navTransparent: boolean;
  basketQuantity: number;
  closeBasket: () => void;
  basket?: BasketFragment;
  showBasket: boolean;
  setShowBasket: (value: boolean) => void;
  setSearchOpen: (value: boolean) => void;
  viewOnly?: boolean;
};

const MobileNav = ({
  user,
  navTransparent,
  closeBasket,
  basketQuantity,
  basket,
  showBasket,
  setShowBasket,
  setSearchOpen,
  viewOnly,
}: Props) => {
  const [showNav, toggleNav] = useToggle(false);

  const navItems = [
    { title: "Shop", link: "/shop" },
    { title: "Artists", link: "/artists" },
    { title: "Gallery Walls", link: "/gallery-walls" },
    { title: "About", link: "/about" },
    { title: "Trade", link: "/trade" },
    { title: "GoodNews", link: "/goodnews" },
    { title: "Sell", link: "/sell" },
  ];

  const footerNavItems = [
    { title: "Contact", link: "/contact" },
    { title: "FAQs", link: "/faqs" },
  ];

  return (
    <>
      <Stack justifyContent="space-between" direction="row" width="100%">
        <Stack gap={1.5} direction="row" alignItems="center">
          <NavItem color={navTransparent ? colors.white : colors.black} onClick={toggleNav}>
            <MenuRounded />
          </NavItem>
          <NavItem color={navTransparent ? colors.white : colors.black} onClick={() => setSearchOpen(true)}>
            <SearchRounded />
          </NavItem>
        </Stack>
        <Link to="/">
          <NavItem color={navTransparent ? colors.white : colors.black}>
            <Icon icon="logo" width={48} height={30} fill={navTransparent ? colors.white : colors.black} />
          </NavItem>
        </Link>
        <Stack gap={1.5} direction="row" alignItems="center">
          <Link to="/wishlist">
            <NavItem color={navTransparent ? colors.white : colors.black} style={{ marginTop: "2px" }}>
              <BookmarkBorderOutlined />
            </NavItem>
          </Link>
          <BasketNavItem
            basket={basket}
            showBasket={showBasket}
            setShowBasket={setShowBasket}
            closeBasket={closeBasket}
            basketQuantity={basketQuantity}
            isMobileScreen={true}
            viewOnly={viewOnly}
            navTransparent={navTransparent}
          />
        </Stack>
      </Stack>
      <Drawer open={showNav} onClose={toggleNav} anchor="left" PaperProps={{ sx: { backgroundColor: colors.black } }}>
        <Box bgcolor={colors.black} width="90vw" height="100%" paddingX={2} paddingTop={2} paddingBottom={3}>
          <Stack gap={3} height="100%">
            <Stack justifyContent="space-between" alignItems="center" width="100%" direction="row">
              <IconButton onClick={toggleNav} style={{ padding: 0 }}>
                <CloseRounded color="secondary" />
              </IconButton>
              <Link to="/">
                <NavItem onClick={toggleNav}>
                  <Icon icon="logo" width={48} height={22} fill={colors.white} />
                </NavItem>
              </Link>
              <div style={{ width: "27.5px" }}></div>
            </Stack>

            <Stack justifyContent="space-between" gap={2} width="100%" height="100%">
              <Stack onClick={toggleNav} gap={2}>
                {navItems.map((item) => (
                  <Link to={item.link} key={item.title}>
                    <NavItem fontSize={24} color={colors.white}>
                      {item.title} <Icon icon="chevronRight" fill={colors.white} />
                    </NavItem>
                  </Link>
                ))}
              </Stack>

              <Stack justifyContent="center" gap={2} width="100%">
                <div onClick={toggleNav}>
                  <Account isMobile />
                </div>
                {footerNavItems.map((item) => (
                  <Link to={item.link} key={item.title} onClick={toggleNav}>
                    <NavItem fontSize={14} color={colors.grey40}>
                      {item.title}
                    </NavItem>
                  </Link>
                ))}
                <a href="https://www.instagram.com/goodmoodprints/" target="_blank" rel="noreferrer">
                  <NavItem fontSize={14} color={colors.grey40}>
                    Instagram
                  </NavItem>
                </a>
                <CountrySelector navTransparent={true} fontSize={14} color={colors.grey40} />
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default MobileNav;
