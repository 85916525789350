import { capitalize } from "lodash";
import styled from "styled-components";
import { Box, MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { CancelRounded } from "@mui/icons-material";
import { frames } from "../../types/product";
import FrameBadge from "../../components/FrameBadges/FrameBadge";
import { getAvailableSizes, getSizeAndFrame } from "../../helpers/product";
import { SearchProductFragment } from "../../generated/storefront";
import DynamicFrameMockUp from "../../components/FrameMockUp/DynamicFrameMockUp";

type Props = {
  product: SearchProductFragment;
  selectedVariantId: string;
  setSelectedVariant: (productId: string, variantId: string) => void;
  removeProductFromWall: (productId: string) => void;
  scale: number;
};

const MockUpWrapper = styled(Box)({
  position: "relative",
  height: "auto",
  width: "fit-content",
  "&:hover > svg": {
    opacity: 1,
  },
  "&:hover > div": {
    opacity: 1,
  },
});

const ProductMockUp = ({ product, selectedVariantId, setSelectedVariant, removeProductFromWall, scale }: Props) => {
  const selectedVariant = product.variants.nodes.find((variant) => variant.id === selectedVariantId);
  const { frame: selectedFrame, size: selectedSize } = selectedVariant
    ? getSizeAndFrame(selectedVariant.selectedOptions)
    : { frame: frames[frames.length - 1], size: getAvailableSizes(product.variants.nodes)[0] };

  const onFrameChange = ({ target }: SelectChangeEvent) => {
    const selectedVariant = product.variants.nodes.find((variant) => {
      const { size, frame } = getSizeAndFrame(variant.selectedOptions);
      return size === selectedSize && frame === target.value;
    });
    if (selectedVariant) {
      setSelectedVariant(product.id, selectedVariant.id);
    }
  };

  const onSizeChange = ({ target }: SelectChangeEvent) => {
    const selectedVariant = product.variants.nodes.find((variant) => {
      const { size, frame } = getSizeAndFrame(variant.selectedOptions);
      return size === target.value && frame === selectedFrame;
    });
    if (selectedVariant) {
      setSelectedVariant(product.id, selectedVariant.id);
    }
  };

  return (
    <MockUpWrapper marginTop={3} marginBottom={5} marginX={3}>
      <CancelRounded
        color="action"
        fontSize="small"
        onClick={() => removeProductFromWall(product.id)}
        sx={{ cursor: "pointer", position: "absolute", top: -10, right: -10, zIndex: 1, opacity: 0 }}
      />
      <DynamicFrameMockUp
        key={product.id}
        image={product.images.nodes[0]}
        frame={selectedFrame}
        size={selectedSize}
        scale={scale}
        shadow
      />
      <Stack
        direction="row"
        gap={1}
        sx={{
          position: "absolute",
          bottom: "-44px",
          right: "50%",
          transform: "translateX(50%)",
          zIndex: 1,
          opacity: 0,
          "&:has(> div.Mui-focused)": { opacity: 1 },
        }}
      >
        <Select variant="filled" onChange={onFrameChange} value={selectedFrame}>
          {frames.map((frame) => (
            <MenuItem key={frame} value={frame}>
              <Stack direction="row" gap={1} alignItems="center">
                <FrameBadge frame={frame} size={16} />
                <Typography fontSize={14}>{capitalize(frame)}</Typography>
              </Stack>
            </MenuItem>
          ))}
        </Select>
        <Select variant="filled" onChange={onSizeChange} value={selectedSize}>
          {getAvailableSizes(product.variants.nodes).map((size) => (
            <MenuItem key={size} value={size}>
              <Typography fontSize={14}>{capitalize(size)}</Typography>
            </MenuItem>
          ))}
        </Select>
      </Stack>
    </MockUpWrapper>
  );
};

export default ProductMockUp;
