import { useContext, useState } from "react";
import {
  CartLineInput,
  useAddItemToBasketMutation,
  useCreateBasketMutation,
  useGetBasketLazyQuery,
} from "../generated/storefront";
import { trackEvent } from "../helpers/analytics";
import { useAppState } from "../state";
import { mountingProductVariantIds } from "../state/constants";
import { Size } from "../types/product";
import { triggerAddToCartEvent } from "../services/API/events";
import AuthContext from "../state/auth";

const useBasketActions = () => {
  const { customer } = useContext(AuthContext);
  const { selectedCountry, setBasket, setItemAdded, customerEmail } = useAppState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>("");
  const [getBasket] = useGetBasketLazyQuery();
  const [createBasket] = useCreateBasketMutation();
  const [addItemToBasket] = useAddItemToBasketMutation();

  const addOrCreateBasket = async (lines: (CartLineInput & { size: Size })[]) => {
    trackEvent("AddToCart", {
      content_ids: lines.map((line) => line.merchandiseId),
      content_type: "product",
    });
    setLoading(true);
    const basketId = localStorage.getItem("basketId");
    const isValidBasket = basketId ? await getBasket({ variables: { basketId } }) : null;
    const moutedItems = lines.filter((line) => line.attributes?.find((attr) => attr.key === "mounted"));
    const mountingLineItems = moutedItems.map((item) => {
      return {
        merchandiseId: mountingProductVariantIds[item.size],
        quantity: item.quantity,
      };
    });
    const lineItems = lines.map((item) => ({
      attributes: item.attributes,
      merchandiseId: item.merchandiseId,
      quantity: item.quantity,
    }));

    try {
      if (basketId && isValidBasket?.data?.cart?.id) {
        const { data } = await addItemToBasket({
          variables: { basketId, lines: [...mountingLineItems, ...lineItems] },
        });
        if (data?.cartLinesAdd?.cart) {
          setBasket(data.cartLinesAdd.cart);
          triggerAddToCartEvent({
            basket: data.cartLinesAdd.cart,
            addedItemId: lines[0].merchandiseId,
            customerInfo: {
              email: customer?.email || customerEmail,
              firstName: customer?.firstName,
              lastName: customer?.lastName,
            },
          });
        }
      } else {
        trackEvent("CreateCart");
        const { data } = await createBasket({
          variables: {
            lines: [...mountingLineItems, ...lineItems],
            buyerIdentity: {
              countryCode: selectedCountry,
            },
          },
        });
        if (data?.cartCreate?.cart) {
          setBasket(data.cartCreate.cart);
          localStorage.setItem("basketId", data.cartCreate.cart.id);

          triggerAddToCartEvent({
            basket: data.cartCreate.cart,
            addedItemId: lines[0].merchandiseId,
            customerInfo: {
              email: customer?.email || customerEmail,
              firstName: customer?.firstName,
              lastName: customer?.lastName,
            },
          });
        }
      }
      setLoading(false);
      setItemAdded(true);
    } catch (e) {
      setLoading(false);
      setError("Could not add item to basket");
    }
  };

  return { getBasket, addOrCreateBasket, loading, error };
};

export default useBasketActions;
