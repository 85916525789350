import { useNavigate } from "react-router-dom";
import { uniq } from "lodash";
import {
  Autocomplete,
  Chip,
  DialogContent,
  OutlinedInput,
  RadioGroup,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import Button from "../../components/Button";
import Radio from "../../components/Radio";
import { useContext, useState } from "react";
import { PrivacyType } from "../../types/wishlist";
import { createWishlist } from "../../services/Firebase/wishlists";
import { sendWishlistCollaboratorRequestEmail } from "../../services/API/emails";
import AuthContext, { Customer } from "../../state/auth";

type Props = {
  open: boolean;
  onClose: () => void;
  customer: Customer;
  productId?: string;
  redirectToWishlist?: boolean;
};

const NewWishlistModal = ({ open, onClose, customer, productId, redirectToWishlist }: Props) => {
  const { refetchCustomer } = useContext(AuthContext);
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [collboratorEmails, setCollboratorEmails] = useState<string[]>([]);
  const [privacyType, setPrivacyType] = useState<PrivacyType>("private");
  const [inputValue, setInputValue] = useState<string>("");
  const [options, setOptions] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleEmailInputChange = (_: any, newValue: string) => {
    setInputValue(newValue);
    setOptions([newValue]);
  };

  const onCollaboratorsEmailChange = (_: any, newValue: string[]) => {
    setInputValue("");
    // split emails by comma or space
    const newEmails = newValue
      .join(",")
      .split(/,| /)
      .filter((email: string) => email);
    const uniqueEmails = uniq(newEmails);
    setCollboratorEmails(uniqueEmails);
  };

  const onCreate = async () => {
    setLoading(true);
    const wishlist = await createWishlist({
      name,
      privacyType,
      ownerId: customer.id,
      collaborators: collboratorEmails.map((email) => ({ email, status: "pending" })),
      productIds: productId ? [productId] : [],
    });
    if (!wishlist) {
      setLoading(false);
      return setError("Failed to create wishlist");
    }
    if (privacyType === "collaborative") {
      await Promise.all(
        collboratorEmails.map(async (email) => {
          sendWishlistCollaboratorRequestEmail({
            to: { email },
            dynamicTemplateData: {
              wishlistId: wishlist.id,
              wishlistName: name,
              customerName: customer.firstName || "A GoodMood customer",
              link: `${window.location.origin}/wishlist/${wishlist.id}?source=email_invite`,
            },
          });
        })
      );
    }
    refetchCustomer();
    onClose();
    if (redirectToWishlist) {
      navigate(`/wishlist/${wishlist.id}`);
    } else {
      setSnackbarOpen(true);
    }
    setLoading(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <CloseRounded
          onClick={onClose}
          fontSize="small"
          sx={{ cursor: "pointer", position: "absolute", top: 0, right: 0, margin: 2 }}
        />
        <DialogContent>
          <Typography variant="h3" marginBottom={1}>
            New Wishlist
          </Typography>
          <Stack gap={2}>
            <OutlinedInput placeholder="Name of wishlist" value={name} onChange={onNameChange} />
            <RadioGroup
              sx={{ flexDirection: "row", gap: 2 }}
              value={privacyType}
              onChange={({ target }) => setPrivacyType(target.value as PrivacyType)}
            >
              <Radio value="private" label="Private" />
              <Radio value="collaborative" label="Collaborative" />
            </RadioGroup>
            {privacyType === "collaborative" && (
              <Autocomplete
                id="emails-autocomplete"
                multiple
                freeSolo
                value={collboratorEmails}
                onChange={onCollaboratorsEmailChange}
                inputValue={inputValue}
                onInputChange={handleEmailInputChange}
                options={options}
                getOptionLabel={(option) => option}
                renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Collaborators email" />}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => <Chip variant="outlined" label={option} {...getTagProps({ index })} />)
                }
                clearOnBlur={false}
              />
            )}
            <Button
              onClick={onCreate}
              fullWidth
              loading={loading}
              disabled={!name || (privacyType === "collaborative" && collboratorEmails.length === 0)}
            >
              Create
            </Button>
            {error && <Typography color="error">{error}</Typography>}
          </Stack>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message="Wishlist created successfully"
      />
    </>
  );
};

export default NewWishlistModal;
