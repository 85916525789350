import { useContext, useEffect, useRef, useState } from "react";
import { useDebounce } from "react-use";
import Draggable from "react-draggable";
import html2canvas from "html2canvas";
import styled from "styled-components";
import { match } from "ts-pattern";
import { uniq } from "lodash";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Drawer,
  FilledInput,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import { AddCircleOutlineRounded, CancelRounded, CloseRounded, ExpandMoreRounded, Search } from "@mui/icons-material";
import Button from "../../components/Button";
import background1 from "../../assets/images/galleryWallBuilder/01.png";
import background2 from "../../assets/images/galleryWallBuilder/02.png";
import background3 from "../../assets/images/galleryWallBuilder/03.png";
import background4 from "../../assets/images/galleryWallBuilder/04.png";
import background5 from "../../assets/images/galleryWallBuilder/05.png";
import background6 from "../../assets/images/galleryWallBuilder/06.png";
import background7 from "../../assets/images/galleryWallBuilder/07.png";
import background9 from "../../assets/images/galleryWallBuilder/09.png";
import background10 from "../../assets/images/galleryWallBuilder/10.png";
import background11 from "../../assets/images/galleryWallBuilder/11.png";
import background12 from "../../assets/images/galleryWallBuilder/12.png";
import background13 from "../../assets/images/galleryWallBuilder/13.png";
import background14 from "../../assets/images/galleryWallBuilder/14.png";
import background15 from "../../assets/images/galleryWallBuilder/15.png";
import background16 from "../../assets/images/galleryWallBuilder/16.png";
import background17 from "../../assets/images/galleryWallBuilder/17.png";
import background18 from "../../assets/images/galleryWallBuilder/18.png";
import background19 from "../../assets/images/galleryWallBuilder/19.png";
import background20 from "../../assets/images/galleryWallBuilder/20.png";
import background21 from "../../assets/images/galleryWallBuilder/21.png";
import background22 from "../../assets/images/galleryWallBuilder/22.png";
import background23 from "../../assets/images/galleryWallBuilder/23.png";
import background24 from "../../assets/images/galleryWallBuilder/24.png";
import { colors } from "../../theme";
import { Image } from "../../components";
import { last12Months } from "../../state/constants";
import { useSearchParams } from "react-router-dom";
import ProductCard from "./ProductCard";
import {
  ProductSortKeys,
  SearchProductFragment,
  useGetArtistProductsLazyQuery,
  useGetProductsForProductCardLazyQuery,
  useSearchLazyQuery,
} from "../../generated/storefront";
import ProductMockUp from "./ProductMockUp";
import useBasketActions from "../../hooks/useBasketActions";
import Switch from "../../components/Switch";
import { appendArtistsToProducts, getAvailableSizes, getSizeAndFrame, productSearchFilter } from "../../helpers/product";
import { useAppState } from "../../state";
import { ProductWithArtist, frames } from "../../types/product";
import { User } from "../../types/user";
import { Loader } from "../../components/Loader";
import AuthContext from "../../state/auth";
import Range from "../../components/Form/Range";
import { useShop } from "../../pages/Shop/useShop";
import useFilterOptions from "../../pages/Shop/Filters/useFilterOptions";
import CheckboxSelect from "../../pages/Shop/Filters/Bar/CheckboxSelect";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const UploadButton = styled("label")({
  position: "relative",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: 8,
  width: "100%",
  height: "100px",
  backgroundColor: colors.purple20,
  border: 0,
  borderRadius: 8,
  "&:hover": {
    backgroundColor: colors.purple40,
  },
});

type SearchProduct = SearchProductFragment & { artist: User | null };

type Props = {
  drawerIsOpen: boolean;
  toggleDrawerIsOpen: () => void;
};

const GalleryWallBuilder = ({ drawerIsOpen, toggleDrawerIsOpen }: Props) => {
  const { user, customer } = useContext(AuthContext);
  const { selectedCountry, isMobileScreen } = useAppState();
  const [searchParams, setSearchParams] = useSearchParams();
  const scrollRef = useRef<HTMLDivElement>(null);
  const downloadRef = useRef<HTMLDivElement>(null);
  const [scale, setScale] = useState(0.5);
  const [backgroundImages, setBackgroundImages] = useState([
    background1,
    background2,
    background3,
    background4,
    background5,
    background6,
    background7,
    background9,
    background10,
    background11,
    background12,
    background13,
    background14,
    background15,
    background16,
    background17,
    background18,
    background19,
    background20,
    background21,
    background22,
    background23,
    background24,
  ]);
  const filters = ["sort", "month", "medium", "size", "style", "subject", "orientation"];
  let filterCount = 0;
  searchParams.forEach((value, key) => filters.includes(key) && value && filterCount++);
  const [selectedProducts, setSelectedProducts] = useState<SearchProductFragment[]>([]);
  const [selectedBackground, setSelectedBackground] = useState<string | null>(null);
  const [selectedVariants, setSelectedVariants] = useState<Record<string, string>>({});
  const [inputValue, setInputValue] = useState("");
  const [downloading, setDownloading] = useState(false);
  const [selectedSwitchOption, setSelectedSwitchOption] = useState<"all" | "artists" | "wishlist">(user ? "artists" : "all");
  const { mediumOptions, sizeOptions, subjectOptions, styleOptions, orientationOptions, sortByOptions } = useFilterOptions();
  const { products, clearFilters, loading, hasMore: shopHasMore, loadMore } = useShop({ variantLimit: 20 });
  const { addOrCreateBasket, loading: loadingBasket } = useBasketActions();
  const wishlistIds = JSON.parse(localStorage.getItem("wishlist") || "[]");
  const [wishlistProducts, setWishlistProducts] = useState<ProductWithArtist[]>([]);
  const [searchedProducts, setSearchedProducts] = useState<SearchProduct[]>([]);
  const [artistsProducts, setAritstsProducts] = useState<ProductWithArtist[]>([]);
  const [productsToShow, setProductsToShow] = useState<ProductWithArtist[] | SearchProduct[]>([]);
  const [getProducts] = useGetProductsForProductCardLazyQuery();
  const [search, { data: searchedProductsData, fetchMore: searchFetchMore, loading: loadingSearch }] = useSearchLazyQuery();
  const [getAristProducts, { data: artistsProductsData, fetchMore: artistProductsFetchMore, loading: loadingArtitsProducts }] =
    useGetArtistProductsLazyQuery({
      variables: {
        query: `vendor:${user?.id}`,
        sortKey: ProductSortKeys.CreatedAt,
        limit: 24,
        variantLimit: 20,
        country: selectedCountry,
      },
    });
  const [getWishlistItems, { data: wishlistItemsData, loading: loadingWishlist }] = useGetProductsForProductCardLazyQuery({
    variables: {
      query: `id:${wishlistIds.join(" OR ")}`,
      country: selectedCountry,
      limit: 24,
      variantLimit: 20,
    },
  });

  const productData = match(selectedSwitchOption)
    .with("all", () =>
      inputValue
        ? {
            hasMore: searchedProductsData?.search.pageInfo.hasNextPage || false,
            loadMore: () =>
              searchFetchMore({
                variables: {
                  afterCursor: searchedProductsData?.search.pageInfo.endCursor,
                },
              }),
            loading: loadingSearch,
          }
        : { hasMore: shopHasMore, loadMore, loading }
    )
    .with("artists", () => {
      const hasMore = artistsProductsData?.products.pageInfo.hasNextPage || false;
      const afterCursor = artistsProductsData?.products.pageInfo.endCursor || "";
      return { hasMore, loadMore: () => artistProductsFetchMore({ variables: { afterCursor } }), loading: loadingArtitsProducts };
    })
    .with("wishlist", () => {
      const hasMore = wishlistItemsData?.products.pageInfo.hasNextPage || false;
      const afterCursor = wishlistItemsData?.products.pageInfo.endCursor || "";
      return { hasMore, loadMore: () => artistProductsFetchMore({ variables: { afterCursor } }), loading: loadingWishlist };
    })
    .exhaustive();

  useEffect(() => {
    const prods = match(selectedSwitchOption)
      .with("all", () => (inputValue ? searchedProducts : products || []))
      .with("artists", () => artistsProducts)
      .with("wishlist", () => wishlistProducts)
      .exhaustive();

    setProductsToShow(prods);
  }, [selectedSwitchOption, inputValue, searchedProducts, products, artistsProducts, wishlistProducts]);

  useEffect(() => {
    setInputValue("");
  }, [searchParams.size]);

  useEffect(() => {
    if (user && !loadingArtitsProducts && !artistsProductsData?.products?.nodes?.length) {
      getAristProducts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (wishlistIds.length && !loadingWishlist && !wishlistItemsData?.products?.nodes.length) {
      getWishlistItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wishlistIds]);

  const setSelectedVariant = (productId: string, variantId: string) =>
    setSelectedVariants({ ...selectedVariants, [productId]: variantId });

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedBackground(reader.result as string);
        setBackgroundImages([reader.result as string, ...backgroundImages]);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSortByChange = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    searchParams.set("sort", value);
    setSearchParams(searchParams, { preventScrollReset: true });
  };

  const addProductToWall = (product: SearchProductFragment) => {
    if (selectedProducts.find((p) => p.id === product.id)) {
      return;
    }
    setSelectedProducts([...selectedProducts, product]);
    const defaultVariant = product.variants.nodes.find((variant) => {
      const { size, frame } = getSizeAndFrame(variant.selectedOptions);
      const availableSizes = getAvailableSizes(product.variants.nodes);
      return size === availableSizes[availableSizes.length - 1] && frame === frames[frames.length - 1];
    });
    if (defaultVariant) {
      setSelectedVariants({ ...selectedVariants, [product.id]: defaultVariant.id });
    }
  };

  const removeProductFromWall = (id: string) => {
    setSelectedProducts(selectedProducts.filter((product) => product.id !== id));
    const newVariants = { ...selectedVariants };
    delete newVariants[id];
    setSelectedVariants(newVariants);
  };

  const getSizeOfSelectedVariant = (productId: string) => {
    const variant = productsToShow
      .find((product) => product.id === productId)
      ?.variants.nodes.find((v) => v.id === selectedVariants[productId]);
    if (!variant) throw new Error("Variant not found");
    return getSizeAndFrame(variant?.selectedOptions).size;
  };

  const addAllItemsToBasket = () => {
    addOrCreateBasket(
      Object.entries(selectedVariants).map(([productId, merchandiseId]) => ({
        merchandiseId,
        size: getSizeOfSelectedVariant(productId),
        quantity: 1,
      }))
    );
  };

  const downloadWall = async () => {
    setDownloading(true);
    const element = downloadRef.current as unknown as HTMLElement;
    const canvas = await html2canvas(element, {
      useCORS: true,
      scale: 10,
    });

    const data = canvas.toDataURL("image/png");
    const link = document.createElement("a");

    if (typeof link.download === "string") {
      link.href = data;
      link.download = "gallery_wall.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
    setDownloading(false);
  };

  useDebounce(
    () => {
      if (inputValue) {
        search({ variables: { query: inputValue, country: selectedCountry, limit: 12, variantLimit: 20 } });
      }
    },
    500,
    [inputValue]
  );

  const getAllWishlistProducts = async () => {
    if (customer) {
      const wishlistProductIds = customer.wishlists.flatMap((list) => list.productIds);
      if (wishlistProductIds.length === 0) return setWishlistProducts([]);
      const { data } = await getProducts({
        variables: {
          limit: 50,
          query: `id:${uniq(wishlistProductIds).join(" OR ")}`,
          country: selectedCountry,
        },
      });
      const productsWithArtists = await appendArtistsToProducts(data?.products.nodes || []);
      setWishlistProducts(productsWithArtists);
    } else {
      if (wishlistIds.length && wishlistItemsData?.products) {
        const productsWithArtists = await appendArtistsToProducts(wishlistItemsData.products.nodes);
        setWishlistProducts(productsWithArtists);
      }
    }
  };

  useEffect(() => {
    if (searchedProductsData?.search.nodes) {
      const searchedProducts = searchedProductsData.search.nodes.filter(productSearchFilter) as SearchProductFragment[];
      const getProductsArtists = async () => {
        const productsWithArtists = await appendArtistsToProducts(searchedProducts);
        setSearchedProducts(productsWithArtists);
      };
      getProductsArtists();
    }
  }, [searchedProductsData]);

  useEffect(() => {
    getAllWishlistProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wishlistItemsData, wishlistIds.length, customer]);

  useEffect(() => {
    if (artistsProductsData?.products?.nodes) {
      const getProductsArtists = async () => {
        const productsWithArtists = await appendArtistsToProducts(artistsProductsData?.products?.nodes || []);
        setAritstsProducts(productsWithArtists);
      };
      getProductsArtists();
    }
  }, [artistsProductsData]);

  return (
    <Drawer anchor="bottom" open={drawerIsOpen && !isMobileScreen}>
      <Box height="100vh">
        <Stack
          gap={2}
          paddingX={3}
          paddingY={2}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          bgcolor={colors.grey02}
        >
          <Typography variant="h2" fontSize={28}>
            GoodMood
          </Typography>
          <Stack gap={3} direction="row" alignItems="center" width="100%" justifyContent="end">
            <Stack gap={1.5} direction="row" alignItems="center" width="35%">
              <Typography style={{ whiteSpace: "nowrap" }}>Scale artwork:</Typography>
              <Range min={0} max={2} step={0.1} value={scale} onChange={({ target }) => setScale(target.value)} />
            </Stack>
            <Stack gap={1.5} direction="row" alignItems="center">
              <Button
                secondary
                onClick={downloadWall}
                loading={downloading}
                size="medium"
                disabled={selectedProducts.length === 0}
              >
                <Stack direction="row" gap={0.5} alignItems="center">
                  Download
                </Stack>
              </Button>
              <Button
                size="medium"
                onClick={addAllItemsToBasket}
                disabled={selectedProducts.length === 0}
                loading={loadingBasket}
              >
                Add wall to basket
              </Button>
              <IconButton onClick={toggleDrawerIsOpen}>
                <CloseRounded fontSize="small" />
              </IconButton>
            </Stack>
          </Stack>
        </Stack>

        <Stack direction="row" height="calc(100vh - 72px)">
          <Box width="25%" overflow="auto" paddingY={2} minWidth={356} ref={scrollRef}>
            <Box paddingX={2}>
              <FilledInput
                name="search"
                id="search"
                size="small"
                hiddenLabel
                autoFocus
                fullWidth
                value={inputValue}
                onChange={({ target }) => setInputValue(target.value)}
                placeholder="Search"
                startAdornment={
                  <InputAdornment position="start">
                    <Search fontSize="small" />
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <CancelRounded
                      color="action"
                      fontSize="small"
                      onClick={() => setInputValue("")}
                      style={{ cursor: "pointer", visibility: inputValue ? "visible" : "hidden" }}
                    />
                  </InputAdornment>
                }
              />
            </Box>
            <Box padding={2}>
              <Switch
                options={[
                  ...(user ? [{ label: "My pieces", value: "artists" }] : []),
                  { label: "All prints", value: "all" },
                  { label: "Wishlist", value: "wishlist" },
                ]}
                selected={selectedSwitchOption}
                onChange={(value) => setSelectedSwitchOption(value as "all" | "artists" | "wishlist")}
                fullWidth
              />
            </Box>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreRounded />} aria-controls="background-content" id="background-header">
                <Typography>Background</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <UploadButton>
                      <Stack gap={1} alignItems="center" color={colors.darkPurple}>
                        <AddCircleOutlineRounded color="inherit" fontSize="small" />
                        <Typography color={colors.darkPurple}>Upload</Typography>
                      </Stack>
                      <VisuallyHiddenInput type="file" onChange={handleImageUpload} />
                    </UploadButton>
                  </Grid>
                  {backgroundImages.map((image, index) => (
                    <Grid item key={index} xs={6} onClick={() => setSelectedBackground(image)}>
                      <Box width="100%" height="100px" borderRadius={8}>
                        <Image
                          src={image}
                          alt="Background"
                          radius="8px"
                          style={{ cursor: "pointer", border: selectedBackground === image ? `3px solid ${colors.black}` : 0 }}
                        />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreRounded />} aria-controls="filters-content" id="filters-header">
                <Typography>Filters {filterCount > 0 ? `(${filterCount})` : ""}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={6} width="100%">
                    <CheckboxSelect width="100%" name="month" options={last12Months} />
                  </Grid>
                  <Grid item xs={6}>
                    <CheckboxSelect width="100%" name="medium" options={mediumOptions} />
                  </Grid>
                  <Grid item xs={6}>
                    <CheckboxSelect width="100%" name="style" options={styleOptions} />
                  </Grid>
                  <Grid item xs={6}>
                    <CheckboxSelect width="100%" name="subject" options={subjectOptions} />
                  </Grid>
                  <Grid item xs={6}>
                    <CheckboxSelect width="100%" name="size" options={sizeOptions} />
                  </Grid>
                  <Grid item xs={6}>
                    <CheckboxSelect width="100%" name="orientation" options={orientationOptions} />
                  </Grid>
                  <Grid item xs={6}>
                    <Select
                      name="sort"
                      variant="filled"
                      displayEmpty
                      onChange={handleSortByChange}
                      renderValue={(selected) => sortByOptions.find((o) => o.value === selected)?.label || "Sort by"}
                      sx={{ width: "100%" }}
                    >
                      {sortByOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          <Typography fontSize={14}>{option.label}</Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12}>
                    <Button tertiary onClick={clearFilters} size="small" fullWidth>
                      Clear
                    </Button>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Box>
              <Grid container spacing={2}>
                {loading || loadingArtitsProducts || loadingWishlist || loadingSearch ? (
                  <Loader />
                ) : productsToShow && productsToShow.length ? (
                  <>
                    {productsToShow.map((product) => (
                      <Grid item key={product.id} xs={6}>
                        <ProductCard
                          artistName={`${product.artist?.firstName} ${product.artist?.lastName}`}
                          product={product}
                          addToWall={addProductToWall}
                          indent
                        />
                      </Grid>
                    ))}
                    {productData.hasMore && (
                      <Stack padding={2} alignItems="center" width="100%" marginLeft={2}>
                        <Button onClick={productData.loadMore} loading={productData.loading}>
                          Load more
                        </Button>
                      </Stack>
                    )}
                  </>
                ) : (
                  <Typography align="center" width="100%" paddingTop={5}>
                    No pieces
                  </Typography>
                )}
              </Grid>
            </Box>
          </Box>

          <Stack
            width="75%"
            height="100%"
            position="relative"
            overflow="hidden"
            ref={downloadRef}
            alignItems="center"
            justifyContent="center"
          >
            <Box position="absolute" top={0} width="100%" height="100%">
              {selectedProducts.map((product, index) => (
                <Draggable bounds="parent" key={product.id}>
                  <Box
                    key={product.id}
                    position="absolute"
                    top={`calc(30% + ${index * 24}px)`}
                    left={`calc(45% + ${index * 24}px)`}
                  >
                    <ProductMockUp
                      product={product}
                      selectedVariantId={selectedVariants[product.id]}
                      setSelectedVariant={setSelectedVariant}
                      removeProductFromWall={removeProductFromWall}
                      scale={scale}
                    />
                  </Box>
                </Draggable>
              ))}
            </Box>
            <div
              style={{
                width: "100%",
                height: "100%",
                backgroundImage: `url(${selectedBackground || background1})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
          </Stack>
        </Stack>
      </Box>
    </Drawer>
  );
};

export default GalleryWallBuilder;
