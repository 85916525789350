import { Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import FrameMockUp from "../FrameMockUp";
import { Card, ProductCardProduct } from ".";
import { ProductCardProductFragment } from "../../generated/storefront";
import { Frame } from "../../types/product";
import { getIdNumber } from "../../helpers/shopify";
import { formattedPrice } from "../../helpers/money";
import { AdminProductFragment } from "../../generated/graphql";

type Props = {
  product: ProductCardProduct;
  frame: Frame;
  indent?: boolean;
  showDecimals?: boolean;
};

const GiftCard = ({ product, frame, indent, showDecimals }: Props) => {
  const id = typeof product.id === "number" ? product.id : getIdNumber(product.id);
  const image = product.images.nodes[0];
  const priceRange = (product as ProductCardProductFragment).priceRange || (product as AdminProductFragment).priceRangeV2;
  const { minVariantPrice } = priceRange;

  return (
    <Stack gap={1} height="100%">
      <Link to={`/products/${id}`} style={{ height: "100%", zIndex: 0 }}>
        <Stack height="100%" width="100%">
          <Card>{product.images.nodes.length > 0 && <FrameMockUp frame={frame} image={image} />}</Card>
          <Stack gap={0.5} paddingX={indent ? 1.5 : 0} paddingTop={2} minHeight={{ xs: "74px", md: "86px" }}>
            <Typography variant="body2" textOverflow="ellipsis" overflow="hidden" style={{ whiteSpace: "nowrap" }}>
              {product.title}
            </Typography>
            <Typography variant="body2">GoodMood</Typography>
            <Typography variant="body2">
              From {formattedPrice(minVariantPrice.amount, minVariantPrice.currencyCode, showDecimals)}
            </Typography>
          </Stack>
        </Stack>
      </Link>
    </Stack>
  );
};

export default GiftCard;
