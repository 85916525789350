import { useMemo, useState } from "react";
import { capitalize } from "lodash";
import { MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import TextLink from "../../components/TextLink";
import { getAvailableSizes, getSizeAndFrame } from "../../helpers/product";
import FrameMockUp from "../../components/FrameMockUp";
import { CollectionProductFragment } from "../../generated/storefront";
import { getIdNumber } from "../../helpers/shopify";
import { User } from "../../types/user";
import { Frame, Size, frames } from "../../types/product";
import { formattedPrice } from "../../helpers/money";
import { colors } from "../../theme";
import FrameBadge from "../../components/FrameBadges/FrameBadge";

type Props = {
  product: CollectionProductFragment & { artist: User | null };
  setSelectedVariant: (productId: string, variantId: string) => void;
  lastChild: boolean;
};

const CollectionItem = ({ product, setSelectedVariant, lastChild }: Props) => {
  const [selectedFrame, setSelectedFrame] = useState(frames[frames.length - 1]);
  const [selectedSize, setSelectedSize] = useState(getAvailableSizes(product.variants.nodes)[0]);
  const selectedVariant = useMemo(() => {
    return product.variants.nodes.find((variant) => {
      const { size, frame } = getSizeAndFrame(variant.selectedOptions);
      return size === selectedSize && frame === selectedFrame;
    });
  }, [product.variants.nodes, selectedSize, selectedFrame]);

  const onFrameChange = ({ target }: SelectChangeEvent) => {
    setSelectedFrame(target.value as Frame);
    const selectedVariant = product.variants.nodes.find((variant) => {
      const { size, frame } = getSizeAndFrame(variant.selectedOptions);
      return size === selectedSize && frame === target.value;
    });
    if (selectedVariant) {
      setSelectedVariant(product.id, selectedVariant.id);
    }
  };

  const onSizeChange = ({ target }: SelectChangeEvent) => {
    setSelectedSize(target.value as Size);
    const selectedVariant = product.variants.nodes.find((variant) => {
      const { size, frame } = getSizeAndFrame(variant.selectedOptions);
      return size === target.value && frame === selectedFrame;
    });
    if (selectedVariant) {
      setSelectedVariant(product.id, selectedVariant.id);
    }
  };

  return (
    <Stack
      key={product.id}
      direction="row"
      justifyContent="space-between"
      borderBottom={lastChild ? 0 : `1px solid ${colors.grey10}`}
      paddingX={{ xs: 2, md: 5 }}
      paddingBottom={2}
      gap={2}
    >
      <Stack gap={2}>
        <Stack gap={0.5}>
          <TextLink to={`/products/${getIdNumber(product.id)}`}>
            <Typography>{product.title}</Typography>
          </TextLink>
          <TextLink to={`/artists/${product.artist?.permalink}`}>
            <Typography fontSize={14}>
              {product.artist ? `${product.artist.firstName} ${product.artist.lastName}` : "GoodMood"}
            </Typography>
          </TextLink>
        </Stack>
        <Stack direction="row" gap={1}>
          <Select variant="filled" onChange={onFrameChange} value={selectedFrame}>
            {frames.map((frame) => (
              <MenuItem key={frame} value={frame}>
                <Stack direction="row" gap={1} alignItems="center">
                  <FrameBadge frame={frame} size={16} />
                  <Typography fontSize={14} lineHeight="100%">
                    {capitalize(frame)}
                  </Typography>
                </Stack>
              </MenuItem>
            ))}
          </Select>
          <Select variant="filled" onChange={onSizeChange} value={selectedSize}>
            {getAvailableSizes(product.variants.nodes).map((frame) => (
              <MenuItem key={frame} value={frame}>
                <Typography fontSize={14} lineHeight="100%">
                  {capitalize(frame)}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </Stack>
        {selectedVariant && (
          <Typography fontSize={14}>
            {formattedPrice(selectedVariant.priceV2.amount, selectedVariant.priceV2.currencyCode)}
          </Typography>
        )}
      </Stack>
      <Stack flexBasis="18%">
        <FrameMockUp image={product.images.nodes[0]} frame={selectedFrame} />
      </Stack>
    </Stack>
  );
};
export default CollectionItem;
