import { Helmet } from "react-helmet";
import { CollectionProductFragment, useGetAllCollectionsQuery, useGetCollectionQuery } from "../../generated/storefront";
import { Box, Stack, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { Loader } from "../../components/Loader";
import { Image } from "../../components";
import ProductCard from "../../components/ProductCard";
import { useEffect, useState } from "react";
import { appendArtistsToProducts, getAvailableSizes, getSizeAndFrame } from "../../helpers/product";
import { User } from "../../types/user";
import ScrollCarousel from "../../components/ScrollCarousel";
import CollectionItem from "./CollectionItem";
import Button from "../../components/Button";
import useBasketActions from "../../hooks/useBasketActions";
import { colors } from "../../theme";
import { Frame, frames } from "../../types/product";
import { formattedPrice } from "../../helpers/money";
import { useAppState } from "../../state";
import { Carousel, CarouselItem } from "../../components/SnapCarousel";
import { PlainMockUp } from "../../components/MockUps";
import CollectionCard from "../../components/Card/Collection";

type ProductsWithArtist = CollectionProductFragment & { artist: User | null };

const Collection = () => {
  const { id } = useParams();
  const { navHeight, isMobileScreen } = useAppState();
  const { addOrCreateBasket, loading: loadingBasket, error } = useBasketActions();
  const [products, setProducts] = useState<ProductsWithArtist[]>([]);
  const [selectedVariants, setSelectedVariants] = useState<Record<string, string>>({});
  const { data, loading } = useGetCollectionQuery({
    variables: {
      id: `gid://shopify/Collection/${id}`,
    },
  });
  const noOfProductsToShow = Number(data?.collection?.productsToShow?.value || 0);
  const productsToShow = noOfProductsToShow ? products.slice(0, noOfProductsToShow) : [];
  const { data: collectionsData } = useGetAllCollectionsQuery();

  const setSelectedVariant = (productId: string, variantId: string) =>
    setSelectedVariants({ ...selectedVariants, [productId]: variantId });

  const getSizeOfSelectedVariant = (productId: string) => {
    const variant = productsToShow
      .find((product) => product.id === productId)
      ?.variants.nodes.find((v) => v.id === selectedVariants[productId]);
    if (!variant) throw new Error("Variant not found");
    return getSizeAndFrame(variant?.selectedOptions).size;
  };

  const addAllItemsToBasket = () => {
    addOrCreateBasket(
      Object.entries(selectedVariants).map(([productId, merchandiseId]) => ({
        merchandiseId,
        size: getSizeOfSelectedVariant(productId),
        quantity: 1,
      }))
    );
  };

  useEffect(() => {
    const defaultVariants: Record<string, string> = {};
    productsToShow.forEach((product) => {
      const defaultVariant = product.variants.nodes.find((variant) => {
        const { size, frame } = getSizeAndFrame(variant.selectedOptions);
        return size === getAvailableSizes(product.variants.nodes)[0] && frame === frames[frames.length - 1];
      });
      if (defaultVariant) defaultVariants[product.id] = defaultVariant.id;
    });
    setSelectedVariants(defaultVariants);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  useEffect(() => {
    const getProductsArtists = async (products: CollectionProductFragment[]) => {
      const productsWithArtist = await appendArtistsToProducts(products);
      setProducts(productsWithArtist);
    };
    if (data?.collection?.products?.nodes) {
      getProductsArtists(data.collection.products.nodes);
    }
  }, [data?.collection]);

  if (!data || loading)
    return (
      <Box padding={2}>
        <Loader />
      </Box>
    );

  const { collection } = data;
  const interiors = collectionsData?.collections.nodes.filter((collection) => collection.image?.url) || [];
  const collections = interiors.filter((c) => c.id !== collection?.id && !/\d/.test(c.title));

  if (!collection)
    return (
      <Box padding={2}>
        <Typography variant="h2" align="center">
          Collection not found
        </Typography>
      </Box>
    );

  const subtotal = productsToShow.reduce((acc, product) => {
    const variant = product.variants.nodes.find((v) => v.id === selectedVariants[product.id]);
    return acc + (variant ? Number(variant.priceV2.amount) : 0);
  }, 0);
  const currencyCode = productsToShow[0]?.variants.nodes[0].priceV2.currencyCode;
  const discountPct = productsToShow.length > 2 ? 0.2 : 0.15;

  return (
    <>
      <Helmet>
        <title>{collection.title} | GoodMood</title>
        <meta name="description" content={`Buy curated collections of ${collection.title} art prints`} />
      </Helmet>
      <Stack direction={{ xs: "column", md: "row" }} width="100%" height={{ xs: "auto", md: `calc(100vh - ${navHeight}px)` }}>
        {productsToShow.length > 0 ? (
          <>
            <Stack flexBasis={{ xs: "100%", md: "50%" }}>
              {isMobileScreen ? (
                productsToShow.length > 0 && (
                  <Box height="50vh">
                    <Carousel
                      items={[
                        {
                          key: "collection-image",
                          component: <Image src={collection.image?.url} />,
                        },
                        ...productsToShow.map((product) => {
                          const frame = selectedVariants[product.id]
                            ? (getSizeAndFrame(
                                product.variants.nodes.find((v) => v.id === selectedVariants[product.id])?.selectedOptions || []
                              ).frame as Frame) || Frame.Natural
                            : Frame.Natural;
                          return {
                            key: product.id,
                            component: <PlainMockUp image={product.images.nodes[0]} frame={frame} isMobile />,
                          };
                        }),
                      ]}
                      renderItem={({ item, isSnapPoint }) => {
                        return (
                          <CarouselItem key={item.key} isSnapPoint={isSnapPoint}>
                            {item.component}
                          </CarouselItem>
                        );
                      }}
                    />
                  </Box>
                )
              ) : (
                <Image src={collection.image?.url} alt={collection.image?.altText || collection.title} />
              )}
            </Stack>
            <Stack flexBasis={{ xs: "100%", md: "50%" }}>
              <Box overflow={{ xs: "initial", md: "auto" }} paddingTop={2}>
                <Stack gap={3}>
                  <Typography variant="h2" component="h1" paddingX={{ xs: 2, md: 5 }}>
                    {collection.title}
                  </Typography>
                  <Stack gap={2}>
                    {productsToShow.map((product, index) => (
                      <CollectionItem
                        product={product}
                        setSelectedVariant={setSelectedVariant}
                        lastChild={index === productsToShow.length - 1}
                      />
                    ))}
                  </Stack>
                </Stack>
                <Box
                  paddingY={1.5}
                  paddingX={{ xs: 2, md: 5 }}
                  bgcolor={colors.white}
                  position="sticky"
                  bottom={0}
                  borderTop={`1px solid ${colors.grey10}`}
                >
                  <Stack gap={{ xs: 1, md: 2 }} alignItems="center">
                    {productsToShow.length && (
                      <Stack gap={{ xs: 0.5, md: 1 }} direction="row" alignSelf="start">
                        <Typography
                          variant="h4"
                          alignSelf="start"
                          style={{ textDecoration: "line-through" }}
                          color="text.disabled"
                        >
                          {formattedPrice(subtotal, currencyCode)}
                        </Typography>
                        <Typography variant="h4" color={colors.red}>
                          {formattedPrice(subtotal * (1 - discountPct), currencyCode)} · {discountPct * 100}% OFF
                        </Typography>
                      </Stack>
                    )}

                    <Button fullWidth onClick={addAllItemsToBasket} loading={loadingBasket}>
                      Add all to basket
                    </Button>
                    {error && <Typography color={colors.error}>{error}</Typography>}
                    <Typography variant="caption" align="center" color="text.secondary" fontSize={{ xs: 12, md: 14 }}>
                      No import fees for UK, EU, AUS & USA
                    </Typography>
                  </Stack>
                </Box>
              </Box>
            </Stack>
          </>
        ) : (
          <Box padding={2} width="100%">
            <Loader />
          </Box>
        )}
      </Stack>

      <Box paddingY={{ xs: 2, md: 5 }}>
        <ScrollCarousel header="Related products" cta={{ text: "View all", link: `/shop/collections/${collection.handle}` }}>
          {products.map((product) => (
            <ProductCard key={product.id} product={product} artist={product.artist} />
          ))}
        </ScrollCarousel>
      </Box>

      <Box paddingY={{ xs: 2, md: 5 }}>
        <ScrollCarousel header="More gallery walls" cta={{ text: "View all", link: "/gallery-walls" }}>
          {collections.map((c) => (
            <CollectionCard key={c.id} collection={c} imageHeight={isMobileScreen ? "50vw" : "25vw"} />
          ))}
        </ScrollCarousel>
      </Box>
    </>
  );
};

export default Collection;
