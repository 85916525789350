import { useEffect, useState } from "react";
import { IconButton, MenuItem, Popover, Stack, Typography } from "@mui/material";
import { updateLocalStorageWishlist } from "../../helpers/product";
import { AddRounded, BookmarkBorderOutlined, BookmarkOutlined } from "@mui/icons-material";
import { getIdNumber } from "../../helpers/shopify";
import WishlistItem from "./WishlistItem";
import { Customer } from "../../state/auth";
import NewWishlistModal from "../../pages/Wishlists/NewWishlistModal";
import { createWishlist, updateWishlist } from "../../services/Firebase/wishlists";

type Props = {
  productId: string;
  customer?: Customer;
  refetchCustomer: () => void;
  popoverOpen: boolean;
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: (value: HTMLButtonElement | null) => void;
};

const WishlistBookmark = ({ productId, customer, refetchCustomer, popoverOpen, anchorEl, setAnchorEl }: Props) => {
  const productIdNumber = getIdNumber(productId);
  const [wishlistIds] = useState(JSON.parse(localStorage.getItem("wishlist") || "[]"));
  const wishlistItemDefault = customer
    ? customer.wishlists.some((wishlist) => wishlist.productIds.includes(productIdNumber))
    : wishlistIds.includes(productIdNumber);
  const [wishlistItem, setWishlistItem] = useState(wishlistItemDefault);
  const [newWishlistModalOpen, setNewWishlistModalOpen] = useState(false);
  const id = popoverOpen ? "simple-popover" : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onAddOrRemoveProduct = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    if (customer) {
      if (customer.wishlists.length === 0) {
        await createWishlist({
          name: "My wishlist",
          ownerId: customer.id,
          productIds: [productIdNumber],
          privacyType: "private",
          collaborators: [],
        });
        return refetchCustomer();
      }

      if (customer.wishlists.length === 1 && customer.wishlists[0].privacyType === "private") {
        if (wishlistItem) {
          await updateWishlist(customer.wishlists[0].id, {
            productIds: customer.wishlists[0].productIds.filter((id) => id !== productIdNumber),
          });
          return refetchCustomer();
        }
        await updateWishlist(customer.wishlists[0].id, { productIds: [...customer.wishlists[0].productIds, productIdNumber] });
        return refetchCustomer();
      }

      setAnchorEl(event.currentTarget);
    } else {
      updateLocalStorageWishlist(productIdNumber);
      setWishlistItem(!wishlistItem);
    }
  };

  useEffect(() => {
    const selected = customer
      ? customer.wishlists.some((wishlist) => wishlist.productIds.includes(productIdNumber))
      : wishlistIds.includes(productIdNumber);
    setWishlistItem(selected);
  }, [customer, productIdNumber, wishlistIds]);

  return (
    <>
      <IconButton aria-describedby={id} size="small" onClick={onAddOrRemoveProduct} style={{ padding: 0, height: "min-content" }}>
        {wishlistItem ? (
          <BookmarkOutlined color="primary" style={{ fontSize: "20px" }} />
        ) : (
          <BookmarkBorderOutlined color="disabled" style={{ fontSize: "20px" }} />
        )}
      </IconButton>
      {customer && (
        <Popover
          id={id}
          open={popoverOpen}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          sx={{
            "& .MuiPopover-paper": {
              minWidth: 200,
            },
          }}
        >
          {customer.wishlists.map((wishlist) => (
            <WishlistItem
              key={wishlist.id}
              productId={productId}
              wishlist={wishlist}
              setWishlistItem={setWishlistItem}
              refetchCustomer={refetchCustomer}
            />
          ))}
          <MenuItem onClick={() => setNewWishlistModalOpen(true)}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" gap={1} width="100%">
              <Typography display="flex" alignItems="center">
                New wishlist
              </Typography>
              <AddRounded fontSize="small" style={{ marginLeft: 6 }} />
            </Stack>
          </MenuItem>
        </Popover>
      )}
      {customer && (
        <NewWishlistModal
          open={newWishlistModalOpen}
          customer={customer}
          productId={productIdNumber}
          onClose={() => setNewWishlistModalOpen(false)}
        />
      )}
    </>
  );
};

export default WishlistBookmark;
