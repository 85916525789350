import { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Box, Grid, Stack, Typography } from "@mui/material";
import placeholder from "../../assets/images/placeholder.png";
import { User } from "../../types/user";
import PageHeader from "../../components/PageHeader";
import { colors } from "../../theme";
import { Header } from "../../components/Text";
import ProductCard from "../../components/ProductCard";
import { isNextMonth } from "../../helpers/time";
import { getUserById } from "../../services/Firebase";
import { Loader } from "../../components/Loader";
import Button from "../../components/Button";
import { useAppState } from "../../state";
import { getUserByPermalink } from "../../services/Firebase/users";
import { ProductSortKeys, useGetArtistProductsQuery } from "../../generated/storefront";
import useLoadMoreOnScroll from "../../hooks/useLoadMoreOnScroll";

const Artist = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [artist, setArtist] = useState<User>();
  const [loadingArtist, setLoadingArtist] = useState(true);
  const { id } = useParams();
  const { selectedCountry, isMobileScreen } = useAppState();
  const { data, loading, fetchMore } = useGetArtistProductsQuery({
    variables: {
      limit: 12,
      query: `vendor:${artist?.id}`,
      sortKey: ProductSortKeys.CreatedAt,
      country: selectedCountry,
    },
  });
  const products = data?.products.nodes;
  const hasMore = data?.products.pageInfo.hasNextPage || false;
  const afterCursor = data?.products.pageInfo.endCursor || "";

  useLoadMoreOnScroll(
    ref,
    () =>
      fetchMore({
        variables: {
          afterCursor,
        },
      }),
    hasMore,
    loading
  );

  const getArtist = async () => {
    if (!id) return;
    const user = (await getUserById(id)) || (await getUserByPermalink(id));
    setArtist(user);
    setLoadingArtist(false);
  };

  const saveScrollPosition = () => {
    window.sessionStorage.setItem("scrollPosition", window.scrollY.toString());
  };

  useEffect(() => {
    setLoadingArtist(true);
    getArtist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <Helmet>
        {artist && <title>{`${artist.firstName} ${artist.lastName}`} | GoodMood</title>}
        {artist?.about && <meta name="description" content={artist.about} />}
        {artist && <link rel="canonical" href={`https://goodmoodprints.io/artists/${artist.permalink}`} />}
      </Helmet>

      <Box minHeight={!artist || !products || loading ? `${window.sessionStorage.getItem("scrollPosition")}px` : "auto"}>
        {loadingArtist ? (
          <Box padding={4}>
            <Loader />
          </Box>
        ) : artist?.firstName ? (
          <>
            <PageHeader
              header={`${artist.firstName} ${artist.lastName}`}
              background={colors.mint}
              image={artist.image || placeholder}
            >
              {artist.about}
            </PageHeader>
            <Box ref={ref} padding={isMobileScreen ? "24px 0" : "40px"}>
              {products && !loading ? (
                products.length === 0 ? (
                  <Box padding="32px 24px 400px">
                    <Stack alignItems="center">
                      <Header type="h2" margin="0 0 16px 0" align="center">
                        Nothing uploaded yet
                      </Header>
                      <Link
                        to={{
                          pathname: "/shop",
                          search: "?collection=true",
                        }}
                      >
                        <Button secondary>Explore the collection</Button>
                      </Link>
                    </Stack>
                  </Box>
                ) : (
                  <>
                    <Grid container spacing={{ xs: 1, md: 2 }} rowGap={{ xs: 4, md: 9 }}>
                      {products.map((product) => (
                        <Grid item xs={6} md={3} key={product.id} onClick={saveScrollPosition}>
                          <ProductCard
                            artist={artist}
                            product={product}
                            nextMonths={isNextMonth(product.productType)}
                            indent={isMobileScreen}
                          />
                        </Grid>
                      ))}
                    </Grid>
                    {loading ? (
                      <Loader />
                    ) : hasMore ? (
                      <Stack width="100%" alignItems="center" padding={5}>
                        <Button onClick={() => fetchMore({ variables: { afterCursor } })}>Load more</Button>
                      </Stack>
                    ) : null}
                  </>
                )
              ) : (
                <Loader />
              )}
            </Box>
          </>
        ) : (
          <Box padding="40px">
            <Stack gap={2} justifyContent="center" alignItems="center">
              <Typography variant="h2" component="h1">
                Artist not found
              </Typography>
              <Link to="/artists">
                <Button secondary>View all artists</Button>
              </Link>
            </Stack>
          </Box>
        )}
      </Box>
    </>
  );
};

export default Artist;
