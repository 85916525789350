import { match } from "ts-pattern";
import { Frame } from "../../types/product";
// import brownFrameBadge from "../../assets/images/brownFrameBadge.png";
import blacklFrameBadge from "../../assets/images/blackFrameBadge.svg";
import whiteFrameBadge from "../../assets/images/whiteFrameBadge.svg";
import naturalFrameBadge from "../../assets/images/naturalFrameBadge.svg";
import unframedBadge from "../../assets/images/unframedBadge.svg";

const FrameBadge = ({ frame, size = 22 }: { frame: Frame; size?: number }) => {
  return (
    match(frame)
      // .with(Frame.Brown, () => <img src={brownFrameBadge} alt="brown frame icon badge" width={size} height={size} />)
      .with(Frame.Natural, () => <img src={naturalFrameBadge} alt="natural frame icon badge" width={size} height={size} />)
      .with(Frame.Black, () => <img src={blacklFrameBadge} alt="black frame icon badge" width={size} height={size} />)
      .with(Frame.White, () => <img src={whiteFrameBadge} alt="white frame icon badge" width={size} height={size} />)
      .with(Frame.Unframed, () => <img src={unframedBadge} alt="unframed icon badge" width={size} height={size} />)
      .exhaustive()
  );
};

export default FrameBadge;
