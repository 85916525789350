import { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { debounce } from "lodash";
import { Autocomplete, IconButton, Stack, TextField, Typography } from "@mui/material";
import { DeleteRounded } from "@mui/icons-material";
import momentData from "moment-timezone/data/meta/latest.json";
import { Container, Section } from "../../../components/Layout";
import { Header, Text } from "../../../components/Text";
import { appendArtistsToProducts, getSizeAndFrame, getSkuCode } from "../../../helpers/product";
import { Frame, RectangleSize, Size, frames, rectangleSizes, squareSizes } from "../../../types/product";
import { colors } from "../../../theme";
import Button from "../../../components/Button";
import { createCustomOrder } from "../../../services/API";
import { getIdNumber } from "../../../helpers/shopify";
import Input from "../../../components/Form/Input";
import Dropdown from "../../../components/Form/Dropdown";
import { BasketFragment, BasketLineItemFragment, CurrencyCode } from "../../../generated/storefront";
import { AdminProductFragment, useGetAdminProductsLazyQuery } from "../../../generated/graphql";
import { OrderItemInput } from "../../../types/order";
import { User } from "../../../types/user";
import Checkbox from "../../../components/Checkbox";
import useBasketActions from "../../../hooks/useBasketActions";

const defaultState = {
  name: "",
  email: "",
  phone: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  postcode: "",
  country: "",
  country_code: "",
};

type OrderItem = OrderItemInput & { product_title: string; image: string; mounted?: boolean; boxFrame?: boolean };
type SearchedProduct = AdminProductFragment & { artist: User | null };

const Checkout = () => {
  const [searchParams] = useSearchParams();
  const basketId = searchParams.get("basketId") || "";
  const { getBasket } = useBasketActions();
  const [getAdminProducts, { loading: loadingProducts }] = useGetAdminProductsLazyQuery();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<{ id: string; title: string }>();
  const [customItemSize, setCustomItemSize] = useState<Size>(RectangleSize.A3);
  const [customItemFrame, setCustomItemFrame] = useState<Frame>(Frame.Unframed);
  const [customItemMounted, setCustomItemMounted] = useState(false);
  const [customItemBoxFrame, setCustomItemBoxFrame] = useState(false);
  const [customItemQty, setCustomItemQty] = useState(1);
  const [searchedProducts, setSearchedProducts] = useState<SearchedProduct[] | null>(null);
  const [orderItems, setOrderItems] = useState<OrderItem[]>([]);
  const [basket, setBasket] = useState<BasketFragment>();
  const [recipientInfo, setRecipientInfo] = useState<{
    name: string;
    email: string;
    phone: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    postcode: string;
    country: string;
    country_code: string;
  }>(defaultState);

  const getVariantTitle = (item: Pick<BasketLineItemFragment, "merchandise">) => {
    const { size, frame } = getSizeAndFrame(item.merchandise.selectedOptions);
    return `${size} / ${frame}`;
  };

  const fetchBasket = async () => {
    if (basketId) {
      const result = await getBasket({ variables: { basketId } });
      if (result.data?.cart) {
        setBasket(result.data.cart);
      }
    }
  };

  useEffect(() => {
    if (basketId) {
      fetchBasket();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basketId]);

  useEffect(() => {
    if (basket?.lines) {
      setOrderItems(
        basket.lines.nodes.map((i) => ({
          quantity: i.quantity,
          id: i.id,
          image: i.merchandise.product.images.nodes[0].src,
          product_id: Number(getIdNumber(i.merchandise.product.id)),
          product_title: i.merchandise.product.title,
          variant_title: getVariantTitle(i),
          mounted: false,
          boxFrame: false,
          sku: i.merchandise.sku,
          merchandise: i.merchandise,
          properties: [],
          price_set: {
            presentment_money: {
              amount: i.cost.subtotalAmount.amount,
              currency_code: i.cost.subtotalAmount.currencyCode,
            },
          },
        }))
      );
    }
  }, [basket?.lines]);

  const handleAdminCheckout = async () => {
    setLoading(true);
    setError("");
    const { name, email, phone, addressLine1, addressLine2, city, postcode, country, country_code } = recipientInfo;
    try {
      await createCustomOrder({
        custom: true,
        email,
        phone,
        line_items: orderItems,
        shipping_address: {
          name,
          address1: addressLine1,
          address2: addressLine2,
          city,
          zip: postcode,
          country,
          country_code,
        },
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(JSON.stringify(e));
    }
  };

  const handleChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
    if (name && value) {
      setRecipientInfo({ ...recipientInfo, [name]: value });
    }
  };

  const handleCountryChange = ({ value, label }: { value: string; label: string }) => {
    setRecipientInfo({ ...recipientInfo, country: label, country_code: value });
  };

  const searchProducts = async (search: string) => {
    const { data } = await getAdminProducts({
      variables: {
        query: `title:${search}*`,
        limit: 20,
      },
    });
    if (data?.products?.nodes) {
      const productsWithArtists = await appendArtistsToProducts(data.products.nodes);
      setSearchedProducts(productsWithArtists);
    }
  };

  const onSelectProduct = (
    _: SyntheticEvent<Element, Event>,
    value: {
      value: string;
      label: string;
    } | null
  ) => {
    if (value?.value) {
      setSelectedProduct({
        id: value.value,
        title: value.label,
      });
    }
  };

  const addItem = () => {
    if (selectedProduct && customItemSize && customItemFrame) {
      const framed = customItemFrame !== Frame.Unframed;
      const newItems = [...orderItems];
      newItems.push({
        id: selectedProduct.id,
        quantity: customItemQty,
        product_id: Number(getIdNumber(selectedProduct.id)),
        product_title: selectedProduct.title,
        image: "",
        variant_title: `${customItemSize} / ${customItemFrame}`,
        sku: getSkuCode(customItemSize, framed, customItemMounted, customItemBoxFrame),
        properties: [],
        price_set: {
          presentment_money: {
            amount: "0",
            currency_code: CurrencyCode.Gbp,
          },
        },
      });
      setOrderItems(newItems);
    }
  };

  const updateItem = (id: string, updatedFields: any) => {
    const newItems = orderItems.map((i) => (i.id === id ? { ...i, ...updatedFields } : i));
    setOrderItems(newItems);
  };

  const removeItem = (id: string) => {
    const newItems = orderItems.filter((i) => i.id !== id);
    setOrderItems(newItems);
  };

  const setMounted = (id: string, mounted: boolean) => {
    const item = orderItems.find((i) => i.id === id);
    if (item) {
      const size = item?.variant_title.split(" / ")[0] as Size;
      const frame = item?.variant_title.split(" / ")[1];
      const framed = frame !== Frame.Unframed;
      updateItem(id, { sku: getSkuCode(size, framed, mounted, item.boxFrame), mounted });
    }
  };

  const setBoxFrame = (id: string, boxFrame: boolean) => {
    const item = orderItems.find((i) => i.id === id);
    if (item) {
      const size = item?.variant_title.split(" / ")[0] as Size;
      const frame = item?.variant_title.split(" / ")[1];
      const framed = frame !== Frame.Unframed;
      updateItem(id, { sku: getSkuCode(size, framed, item.mounted, boxFrame), boxFrame });
    }
  };

  return (
    <Section>
      <Header type="h2" align="center">
        Admin Checkout
      </Header>
      <Container padding="24px">
        <Stack gap={4} direction="row">
          <Stack gap={5} width="30%">
            <Stack gap={2}>
              <Typography variant="h3">Basket</Typography>
              {orderItems.map((item) => (
                <Container key={item.id}>
                  <Stack direction="row" gap={1} justifyContent="space-between" alignItems="center">
                    <img src={item.image} alt={item.product_title} width="50" />
                    <div>
                      <Text size={16} margin="0">
                        {item.quantity}x {item.product_title}
                      </Text>
                      <Text size={14} color={colors.grey40} margin="0">
                        {item.variant_title}
                      </Text>
                    </div>
                    <IconButton onClick={() => removeItem(item.id)}>
                      <DeleteRounded color="primary" fontSize="small" />
                    </IconButton>
                  </Stack>
                  <Stack padding={1} gap={1} direction="row">
                    <Checkbox label="Mounted" onChange={({ target }) => setMounted(item.id, target.checked)} />
                    <Checkbox label="Box Frame" onChange={({ target }) => setBoxFrame(item.id, target.checked)} />
                  </Stack>
                </Container>
              ))}
            </Stack>

            <Stack gap={1}>
              <Typography variant="h5">Add Custom Item</Typography>
              <Autocomplete
                loading={loadingProducts}
                options={
                  searchedProducts?.map((product) => ({
                    value: product.id,
                    label:
                      product.title +
                      " · " +
                      (product.artist ? product.artist.firstName + " " + product.artist.lastName : "GoodMood"),
                  })) || []
                }
                onChange={onSelectProduct}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{ zIndex: 0 }}
                    label="Choose product"
                    onChange={debounce(({ target }) => searchProducts(target.value), 1000)}
                  />
                )}
              />
              <Dropdown
                label="Size"
                onChange={({ value }) => setCustomItemSize(value)}
                options={[...rectangleSizes, ...squareSizes, "A4", "A5", "40X50"].map((size) => ({
                  value: size,
                  label: size,
                }))}
              />
              <Dropdown
                label="Frame"
                onChange={({ value }) => setCustomItemFrame(value)}
                options={[...frames, "brown"].map((frame) => ({ value: frame, label: frame }))}
              />
              <Checkbox label="Mounted" onChange={({ target }) => setCustomItemMounted(target.checked)} />
              <Checkbox label="Box frame" onChange={({ target }) => setCustomItemBoxFrame(target.checked)} />
              <Input
                type="number"
                name="quantity"
                id="quantity"
                label="Quantity"
                defaultValue="1"
                onChange={({ target }) => setCustomItemQty(Number(target.value))}
              />
              <Button onClick={addItem}>Add</Button>
            </Stack>
          </Stack>

          <Stack gap={4} width="70%">
            <Header type="h3">Recipient Info</Header>
            <Stack gap={2}>
              <Stack gap={2} direction="row">
                <Input name="name" id="name" label="Name" onChange={handleChange} />
                <Input name="email" id="email" label="Email" onChange={handleChange} />
                <Input name="phone" id="phone" label="Phone" onChange={handleChange} />
              </Stack>
              <Stack gap={2} direction="row">
                <Input name="addressLine1" id="addressLine1" label="Address Line 1" onChange={handleChange} />
                <Input name="addressLine2" id="addressLine2" label="Address Line 2" onChange={handleChange} />
              </Stack>
              <Stack gap={2} direction="row">
                <Input name="city" id="city" label="City" onChange={handleChange} />
                <Input name="postcode" id="postcode" label="Postcode" onChange={handleChange} />
                <Dropdown
                  label="Country"
                  onChange={handleCountryChange}
                  options={Object.values(momentData.countries).map((c) => ({ value: c.abbr, label: c.name }))}
                  isSearchable
                />
              </Stack>
            </Stack>
            {error && <Text color={colors.error}>{error}</Text>}
            <Button loading={loading} onClick={handleAdminCheckout}>
              Checkout
            </Button>
          </Stack>
        </Stack>
      </Container>
    </Section>
  );
};

export default Checkout;
