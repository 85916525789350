import { Link } from "react-router-dom";
import { Box, Chip, IconButton, Stack, Typography } from "@mui/material";
import {
  AutoAwesomeOutlined,
  Facebook,
  GroupsOutlined,
  Instagram,
  LayersOutlined,
  LinkedIn,
  PlaceOutlined,
  PublicRounded,
  StarOutlineRounded,
} from "@mui/icons-material";
import { useAppState } from "../../state";
import { colors } from "../../theme";
import Reviews from "../Reviews";
import NewsletterInput from "./NewsletterInput";
import { mediumOptions, orientationOptions, styleFilterOptions, subjectFilterOptions } from "../../state/constants";

const linkItems1 = [
  { name: "Shop", path: "/shop" },
  { name: "Gallery Walls", path: "/gallery-walls" },
  { name: "Artists", path: "/artists" },
  { name: "About", path: "/about" },
  { name: "GoodNews", path: "/goodnews" },
  { name: "FAQs", path: "/faqs" },
  { name: "Sell", path: "/sell" },
  { name: "Trade", path: "/trade" },
];

const listItems2 = [
  { name: "Login / Register", path: "/login/artist" },
  { name: "Contact", path: "/contact" },
  { name: "Hanging Guide", path: "/hanging-guide" },
];

const listItems3 = [
  { name: "T&Cs", path: "/terms" },
  { name: "Delivery and Returns", path: "/delivery" },
  { name: "Privacy Policy", path: "/privacy" },
];

const Footer = () => {
  const { isMobileScreen, toggleGalleryWallBuilderIsOpen } = useAppState();
  const hideReviewPages = ["/shop", "/artists", "/sell", "/upload", "/profile", "/admin"];

  return (
    <>
      {!hideReviewPages.some((p) => window.location.pathname.includes(p)) && <Reviews />}
      <Box bgcolor={colors.cardGrey} paddingX={0} paddingY={{ xs: 5, md: 6 }}>
        <Stack gap={4}>
          <Stack gap={1}>
            <Typography color={colors.black} paddingX={{ xs: 2, md: 5 }}>
              Browse Art by Style
            </Typography>
            <Box overflow="auto" width="100%" paddingX={{ xs: 2, md: 5 }}>
              <Stack direction="row" gap={1} flexWrap="wrap" width={{ xs: "220%", md: "100%" }}>
                {styleFilterOptions.map((option) => (
                  <Link
                    to={{
                      pathname: `/shop/collections/${option.collectionHandle}`,
                    }}
                    key={option.value}
                  >
                    <Chip
                      label={option.label}
                      clickable
                      sx={{
                        color: colors.black,
                        backgroundColor: colors.white,
                        fontSize: 14,
                        padding: "12px 16px",
                        ":hover": { backgroundColor: colors.grey10 },
                      }}
                    />
                  </Link>
                ))}
              </Stack>
            </Box>
          </Stack>

          <Stack gap={1}>
            <Typography color={colors.black} paddingX={{ xs: 2, md: 5 }}>
              Browse Art by Subject
            </Typography>
            <Box overflow="auto" width="100%" paddingX={{ xs: 2, md: 5 }}>
              <Stack direction="row" gap={1} flexWrap="wrap" width={{ xs: "120%", md: "100%" }}>
                {subjectFilterOptions.map((option) => (
                  <Link
                    to={{
                      pathname: `/shop/collections/${option.collectionHandle}`,
                    }}
                    key={option.value}
                  >
                    <Chip
                      label={option.label}
                      clickable
                      sx={{
                        color: colors.black,
                        backgroundColor: colors.white,
                        fontSize: 14,
                        padding: "12px 16px",
                        ":hover": { backgroundColor: colors.grey10 },
                      }}
                    />
                  </Link>
                ))}
              </Stack>
            </Box>
          </Stack>

          <Stack gap={1} paddingX={{ xs: 2, md: 5 }}>
            <Typography color={colors.black}>Browse Art by Medium</Typography>
            <Stack direction="row" gap={1} flexWrap="wrap">
              {mediumOptions.map((medium) => (
                <Link
                  to={{
                    pathname: "/shop",
                    search: `?medium=${medium.value}`,
                  }}
                  key={medium.value}
                >
                  <Chip
                    label={medium.label}
                    clickable
                    sx={{
                      color: colors.black,
                      backgroundColor: colors.white,
                      fontSize: 14,
                      padding: "12px 16px",
                      ":hover": { backgroundColor: colors.grey10 },
                    }}
                  />
                </Link>
              ))}
            </Stack>
          </Stack>

          <Stack gap={1} paddingX={{ xs: 2, md: 5 }}>
            <Typography color={colors.black}>Browse Art by Orientation</Typography>
            <Stack direction="row" gap={1} flexWrap="wrap">
              {orientationOptions.map((orientation) => (
                <Link
                  to={{
                    pathname: "/shop",
                    search: `?orientation=${orientation.value}`,
                  }}
                  key={orientation.value}
                >
                  <Chip
                    label={orientation.label}
                    clickable
                    sx={{
                      color: colors.black,
                      backgroundColor: colors.white,
                      fontSize: 14,
                      padding: "12px 16px",
                      ":hover": { backgroundColor: colors.grey10 },
                    }}
                  />
                </Link>
              ))}
            </Stack>
          </Stack>
        </Stack>
      </Box>
      <Box bgcolor={colors.grey90} paddingX={{ xs: 2, md: 5 }} paddingY={{ xs: 4, md: 5 }} overflow="auto">
        <Stack direction="row" gap={3} alignItems="center" justifyContent="space-between">
          <Stack gap={2} alignItems="center" flex={1} minWidth={200}>
            <StarOutlineRounded sx={{ color: colors.salmon }} />
            <Typography fontSize={14} color={colors.white} align="center">
              All of our paper and frames are FSC certified
            </Typography>
          </Stack>

          <Stack gap={2} alignItems="center" flex={1} minWidth={200}>
            <LayersOutlined sx={{ color: colors.salmon }} />
            <Typography fontSize={14} color={colors.white} align="center">
              We only use eco inks, plastic and acid free
            </Typography>
          </Stack>

          <Stack gap={2} alignItems="center" flex={1} minWidth={200}>
            <PlaceOutlined sx={{ color: colors.salmon }} />
            <Typography fontSize={14} color={colors.white} align="center">
              We print and frame globally, locally, to minimise our environmental impact
            </Typography>
          </Stack>

          <Stack gap={2} alignItems="center" flex={1} minWidth={200}>
            <PublicRounded sx={{ color: colors.salmon }} />
            <Typography fontSize={14} color={colors.white} align="center">
              We only print to order, meaning we have zero waste
            </Typography>
          </Stack>

          <Stack gap={2} alignItems="center" flex={1} minWidth={200}>
            <AutoAwesomeOutlined sx={{ color: colors.salmon }} />
            <Typography fontSize={14} color={colors.white} align="center">
              Our museum grade, heavyweight paper ensures Giclée printed colours, POP!
            </Typography>
          </Stack>

          <Stack gap={2} alignItems="center" flex={1} minWidth={200}>
            <GroupsOutlined sx={{ color: colors.salmon }} />
            <Typography fontSize={14} color={colors.white} align="center">
              We are a community dedicated to supporting independent artists
            </Typography>
          </Stack>
        </Stack>
      </Box>
      <Box paddingY={{ xs: 4, md: 10 }} paddingX={{ xs: 2, md: 5 }} bgcolor={colors.black}>
        <Stack direction={{ xs: "column", md: "row" }} gap={5}>
          {isMobileScreen && (
            <Stack width={{ xs: "100%", md: "30%" }}>
              <NewsletterInput />
            </Stack>
          )}
          <Stack direction="row" width={{ xs: "100%", md: "35%" }}>
            <Stack gap={{ xs: 2, md: 4 }} width="50%">
              {linkItems1.map((item) => (
                <Link to={item.path} key={item.path}>
                  <Typography color={colors.white}>{item.name}</Typography>
                </Link>
              ))}
            </Stack>

            <Stack gap={{ xs: 2, md: 4 }} width="50%">
              {listItems2.map((item) => (
                <Link to={item.path} key={item.path}>
                  <Typography color={colors.white}>{item.name}</Typography>
                </Link>
              ))}
              {!isMobileScreen && (
                <>
                  <Typography color={colors.white} onClick={toggleGalleryWallBuilderIsOpen} sx={{ cursor: "pointer" }}>
                    Gallery Wall Builder
                  </Typography>
                  {listItems3.map((item) => (
                    <Link to={item.path} key={item.path}>
                      <Typography color={colors.white}>{item.name}</Typography>
                    </Link>
                  ))}
                </>
              )}
            </Stack>
          </Stack>

          {!isMobileScreen && (
            <Stack width={{ xs: "100%", md: "30%" }}>
              <NewsletterInput />
            </Stack>
          )}

          <Stack gap={2} paddingX={{ xs: 0, md: 3 }} width={{ xs: "100%", md: "30%" }}>
            <Typography color={colors.white}>Follow us</Typography>
            <Stack direction="row" gap={2}>
              <a href="https://www.instagram.com/goodmoodprints/" target="_blank" rel="noreferrer">
                <IconButton
                  sx={{ backgroundColor: colors.grey90, height: 59, width: 59, ":hover": { backgroundColor: colors.grey80 } }}
                >
                  <Instagram color="secondary" fontSize="medium" />
                </IconButton>
              </a>
              <a href="https://www.linkedin.com/company/100945182" target="_blank" rel="noreferrer">
                <IconButton
                  sx={{ backgroundColor: colors.grey90, height: 59, width: 59, ":hover": { backgroundColor: colors.grey80 } }}
                >
                  <LinkedIn color="secondary" fontSize="medium" />
                </IconButton>
              </a>
              <a href="https://www.facebook.com/goodmoodprints" target="_blank" rel="noreferrer">
                <IconButton
                  sx={{ backgroundColor: colors.grey90, height: 59, width: 59, ":hover": { backgroundColor: colors.grey80 } }}
                >
                  <Facebook color="secondary" fontSize="medium" />
                </IconButton>
              </a>
            </Stack>
          </Stack>

          {isMobileScreen && (
            <Stack gap={2}>
              {listItems3.map((item) => (
                <Link to={item.path} key={item.path}>
                  <Typography color={colors.white}>{item.name}</Typography>
                </Link>
              ))}
            </Stack>
          )}
        </Stack>
      </Box>
    </>
  );
};

export default Footer;
