import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { Box, Stack, Typography } from "@mui/material";
import { Frame } from "../../types/product";
import { Container } from "../../components/Layout";
import { getUserById } from "../../services/Firebase";
import { User } from "../../types/user";
import { useAppState } from "../../state";
import { isNextMonth } from "../../helpers/time";
import MockUps, { RoomMockUp, WallMockUp, PlainMockUp } from "../../components/MockUps";
import { Loader } from "../../components/Loader";
import { trackEvent } from "../../helpers/analytics";
import { appendArtistsToProducts, getAvailableSizes } from "../../helpers/product";
import MetaData from "./MetaData";
import SideBar, { CheckoutState } from "./SideBar";
import { giftCardId } from "../../state/constants";
import ScrollCarousel from "../../components/ScrollCarousel";
import ProductCard from "../../components/ProductCard";
import {
  ProductCardProductFragment,
  ProductPageProductFragment,
  ProductSortKeys,
  useGetProductQuery,
  useGetProductsForProductCardQuery,
  useGetRecommendedProductsQuery,
} from "../../generated/storefront";
import { CurrencyCode } from "../../generated/storefront";
import { Carousel, CarouselItem } from "../../components/SnapCarousel";
import CollectionCard from "../../components/Card/Collection";
import WishlistBookmark from "../../components/WishlistBookmark";
import AuthContext from "../../state/auth";
import { colors } from "../../theme";
import QualitySection from "./QualitySection";

type ProductCardProduct = ProductCardProductFragment & { artist: User | null };

const Product = () => {
  const { selectedCountry, selectedMarket, navHeight, isMobileScreen } = useAppState();
  const { customer, refetchCustomer } = useContext(AuthContext);
  const [edited, setEdited] = useState(false);
  const [state, setState] = useState<CheckoutState>({ quantity: 1, mounted: false });
  const [artist, setArtist] = useState<User | null>();
  const [recentlyViewed, setRecentlyViewedProducts] = useState<ProductCardProduct[]>();
  const [moreFromThisArtist, setMoreFromThisArtist] = useState<ProductCardProduct[]>();
  const [youMightAlsoLike, setYouMightAlsoLikeProducts] = useState<ProductCardProduct[]>();
  const { id } = useParams();
  const { data, loading: loadingProduct } = useGetProductQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      id: `gid://shopify/Product/${id}`,
      country: selectedCountry,
    },
  });
  const product = data?.product;
  const recentlyViewedItems = localStorage.getItem("recentlyViewed");
  const recentlyViewedArray = recentlyViewedItems ? JSON.parse(recentlyViewedItems).reverse() : [];
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const popoverOpen = Boolean(anchorEl);

  const { data: youMightAlsoLikeData, refetch: refetchYouMightAlsoLike } = useGetRecommendedProductsQuery({
    variables: {
      productId: `gid://shopify/Product/${id}`,
      country: selectedCountry,
    },
  });
  const { data: moreFromThisArtistData, refetch: refetchMoreFromThisArtist } = useGetProductsForProductCardQuery({
    variables: {
      query: `vendor:${product?.vendor} NOT ${id}`,
      sortKey: ProductSortKeys.CreatedAt,
      reverse: true,
      country: selectedCountry,
      limit: 8,
    },
  });
  const { data: recentlyViewedData, refetch: refetchRecentlyViewed } = useGetProductsForProductCardQuery({
    variables: {
      query: `id:${recentlyViewedArray.join(" OR ")} NOT id:${id}`,
      country: selectedCountry,
      limit: 8,
    },
  });

  const getArtist = async (prod: ProductPageProductFragment) => {
    const productArtist = await getUserById(prod.vendor);
    setArtist(productArtist);
  };

  useEffect(() => {
    if (product?.id) {
      trackEvent("ViewContent", {
        content_ids: [product.id],
        content_type: "product",
      });
      getArtist(product);
      if (id) {
        const recentlyViewed = localStorage.getItem("recentlyViewed");
        if (recentlyViewed) {
          const recentlyViewedArray = JSON.parse(recentlyViewed);
          if (!recentlyViewedArray.includes(id)) {
            if (recentlyViewedArray.length >= 9) {
              recentlyViewedArray.shift();
            }
            recentlyViewedArray.push(id);
            localStorage.setItem("recentlyViewed", JSON.stringify(recentlyViewedArray));
          }
        } else {
          localStorage.setItem("recentlyViewed", JSON.stringify([id]));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product?.id]);

  useEffect(() => {
    const getProductsArtists = async () => {
      const productsWithArtists = await appendArtistsToProducts(
        youMightAlsoLikeData?.productRecommendations?.filter((p) => p.tags.includes("favourite")) || []
      );
      setYouMightAlsoLikeProducts(productsWithArtists);
    };
    getProductsArtists();
  }, [youMightAlsoLikeData]);

  useEffect(() => {
    const getProductsArtists = async () => {
      const productsWithArtists = await appendArtistsToProducts(moreFromThisArtistData?.products?.nodes || []);
      setMoreFromThisArtist(productsWithArtists);
    };
    getProductsArtists();
  }, [moreFromThisArtistData]);

  useEffect(() => {
    const getProductsArtists = async () => {
      const productsWithArtists = await appendArtistsToProducts(recentlyViewedData?.products?.nodes || []);
      setRecentlyViewedProducts(productsWithArtists);
    };
    getProductsArtists();
  }, [recentlyViewedData]);

  useEffect(() => {
    refetchYouMightAlsoLike({
      country: selectedCountry,
    });
    refetchMoreFromThisArtist({
      country: selectedCountry,
    });
    refetchRecentlyViewed({
      country: selectedCountry,
    });
  }, [selectedCountry, refetchMoreFromThisArtist, refetchRecentlyViewed, refetchYouMightAlsoLike]);

  if (loadingProduct) {
    return (
      <Container width={100} style={{ height: `calc(80vh - ${navHeight}px)` }}>
        <Stack alignItems="center" justifyContent="center" style={{ height: "100%" }}>
          <Loader />
        </Stack>
      </Container>
    );
  }

  if (!product)
    return (
      <Container width={100} style={{ height: `calc(80vh - ${navHeight}px)` }}>
        <Stack alignItems="center" justifyContent="center" style={{ height: "100%" }}>
          <Typography>Product not found</Typography>
        </Stack>
      </Container>
    );

  const giftCard = product.id === giftCardId;
  const image = product.images.nodes[0];

  if (giftCard) {
    return (
      <>
        <Helmet>
          <title>Gift Card | GoodMood</title>
          <meta name="description" content="Can't decide? Shop gift cards" />
        </Helmet>
        <Stack direction={{ xs: "column", md: "row" }}>
          <Stack width={{ xs: "auto", md: "67%" }} height={{ xs: "50vh", md: "auto" }}>
            <PlainMockUp frame={state.frame} image={image} isMobile={isMobileScreen} />
          </Stack>
          <SideBar product={product} artist={artist} state={state} setState={setState} setEdited={setEdited} />
        </Stack>
      </>
    );
  }

  const currencyCode = selectedMarket?.regions.nodes[0]?.currency.currencyCode || CurrencyCode.Gbp;
  const showMetaData =
    artist && !isNextMonth(product.productType) && product.tags?.includes("favourite") && currencyCode === CurrencyCode.Usd;
  const sizes = getAvailableSizes(product.variants.nodes).reverse();
  const mockUpSize = edited && state.size ? state.size : sizes[0];
  const interiors = product.collections.nodes.filter((collection) => collection.image?.url) || [];

  const items = [
    {
      comp: PlainMockUp,
      key: "plain",
    },
    {
      comp: RoomMockUp,
      key: "room",
    },
    {
      comp: WallMockUp,
      key: "wall",
    },
  ];

  return (
    <>
      <Helmet>
        {artist && (
          <title>
            {product.title} | {artist.firstName} {artist.lastName}
          </title>
        )}
        <meta name="description" content={product.description ? `${product.title} - ${product.description}` : product.title} />
      </Helmet>
      {showMetaData && <MetaData id={id} product={product} artist={artist} />}
      <Stack direction={{ xs: "column", md: "row" }}>
        <Stack width={{ xs: "auto", md: "67%" }} position="relative">
          {isMobileScreen ? (
            <Box height="50vh">
              <Carousel
                items={items}
                renderItem={({ item, isSnapPoint }) => (
                  <CarouselItem key={item.key} isSnapPoint={isSnapPoint}>
                    <item.comp size={mockUpSize} image={image} frame={state.frame} mounted={state.mounted} isMobile />
                  </CarouselItem>
                )}
              />
            </Box>
          ) : (
            <MockUps image={image} frame={state.frame || Frame.Unframed} size={mockUpSize} mounted={state.mounted} />
          )}
          {!isMobileScreen && (
            <Box
              position="absolute"
              display="flex"
              alignItems="center"
              justifyContent="center"
              top={12}
              right={12}
              width={35}
              height={35}
              borderRadius="100%"
              bgcolor={colors.grey02}
            >
              <WishlistBookmark
                productId={product.id}
                customer={customer}
                refetchCustomer={refetchCustomer}
                popoverOpen={popoverOpen}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
              />
            </Box>
          )}
        </Stack>
        <SideBar product={product} artist={artist} state={state} setState={setState} setEdited={setEdited} />
      </Stack>
      <Stack gap={isMobileScreen ? 6 : 9} paddingY={{ xs: 6, md: 9 }}>
        <QualitySection />
        {artist && moreFromThisArtist && moreFromThisArtist.length > 0 && (
          <div>
            <ScrollCarousel header={`More from ${artist.firstName} ${artist.lastName}`}>
              {moreFromThisArtist.map((p) => <ProductCard product={p} artist={p.artist} key={p.id} />) || []}
            </ScrollCarousel>
          </div>
        )}
        {youMightAlsoLike && youMightAlsoLike.length > 0 && (
          <div>
            <ScrollCarousel header="You might also like">
              {youMightAlsoLike.map((p) => <ProductCard product={p} artist={p.artist} key={p.id} />) || []}
            </ScrollCarousel>
          </div>
        )}
        {interiors.length > 0 && (
          <div>
            <ScrollCarousel header="Related" slideWidth={isMobileScreen ? "60%" : "450px"}>
              {interiors.map((collection) => <CollectionCard key={collection.id} collection={collection} />) || []}
            </ScrollCarousel>
          </div>
        )}
        {recentlyViewed && recentlyViewed.length > 0 && (
          <div>
            <ScrollCarousel header="Recently viewed">
              {recentlyViewed.map((p) => <ProductCard product={p} artist={p.artist} key={p.id} />) || []}
            </ScrollCarousel>
          </div>
        )}
      </Stack>
    </>
  );
};

export default Product;
