import { ChangeEventHandler } from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { uniqueId } from "lodash";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import momentData from "moment-timezone/data/meta/latest.json";
import { User } from "../../../types/user";
import TextLink from "../../../components/TextLink";
import Toolbar from "../Toolbar";
import Search from "../Search";
import Button from "../../../components/Button";

type Props = {
  users?: User[];
  handleSearch: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};

const Artists = ({ users, handleSearch }: Props) => {
  const columns: GridColDef[] = [
    {
      field: "artistId",
      headerName: "Vendor ID",
      width: 220,
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
    },
    {
      field: "paypalEmail",
      headerName: "Paypal Email",
      width: 250,
    },
    {
      field: "city",
      headerName: "City",
      width: 250,
    },
    {
      field: "country",
      headerName: "Country",
      width: 250,
    },
    {
      field: "vatNumber",
      headerName: "VAT/GST Number",
      width: 200,
    },
    {
      field: "lastUploaded",
      headerName: "Last Uploaded",
      width: 200,
      renderCell: (params) => (params.row.lastUploaded ? format(params.row.lastUploaded.toDate(), "do MMM yyyy") : ""),
    },
    {
      field: "artistPage",
      headerName: "Profile Url",
      width: 150,
      renderCell: (params) => (
        <TextLink openInNewTab href={`${process.env.REACT_APP_SITE_URL}/artists/${params.row.permalink}`}>
          View
        </TextLink>
      ),
    },
    {
      field: "ghost",
      headerName: "Ghost",
      width: 150,
      renderCell: (params) => (
        <Link to={`/?ghost=${params.row.artistId}`} target="_blank">
          <Button size="small">Ghost</Button>
        </Link>
      ),
    },
  ];

  const onSave = (event: any) => {
    console.log(event);
  };

  const onRowEditStart = (event: any) => {
    console.log("onRowEditStart");
    console.log(event);
  };

  const onRowEditStop = (event: any) => {
    console.log("onRowEditStop");
    console.log(event);
  };

  const onEditRowsModelChange = (event: any) => {
    console.log("onEditRowsModelChange");
    console.log(event);
  };

  const formattedUsers = (users || []).map(
    ({ id, permalink, firstName, lastName, email, paypalEmail, city, country, vatRegistered, lastUploaded }) => ({
      id: uniqueId(),
      artistId: id,
      permalink,
      name: firstName + " " + lastName,
      email,
      paypalEmail,
      city,
      // @ts-ignore
      country: country && momentData.countries[country]?.name,
      vatNumber: vatRegistered?.vatNumber,
      lastUploaded,
      artistPage: `${process.env.REACT_APP_SITE_URL}/artists/${id}`,
    })
  );

  return (
    <Box sx={{ height: "auto", width: "100%" }} paddingY={3}>
      <Search handleSearch={handleSearch} />
      <DataGrid
        loading={!users}
        rows={formattedUsers}
        columns={columns}
        editMode="row"
        onRowEditStart={onRowEditStart}
        onRowEditStop={onRowEditStop}
        onRowEditCommit={onEditRowsModelChange}
        components={{
          Toolbar,
        }}
        componentsProps={{
          toolbar: {
            onSave,
          },
        }}
        hideFooterPagination
        sx={{
          minHeight: "500px",
        }}
      />
    </Box>
  );
};

export default Artists;
