import ReactPixel from "react-facebook-pixel";
import ReactGA4 from "react-ga4";

export const trackEvent = (eventName: string, data?: Record<string, string | string[]>) => {
  ReactPixel.track(eventName, data);
  const label = !data || Array.isArray(data) ? "" : (Object.values(data)[0] as string);
  ReactGA4.event({
    category: "User",
    action: eventName,
    label,
  });
};
