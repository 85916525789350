import { useContext, useEffect, useState } from "react";
import { endOfMonth, format, startOfMonth } from "date-fns";
import dayjs from "dayjs";
import { Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { DateRange, DateRangePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dictionary, sortBy } from "lodash";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { getUserById } from "../../../../services/Firebase";
import { formattedPrice } from "../../../../helpers/money";
import { getOrderItemTotals } from "../../../../helpers/prices";
import Button from "../../../../components/Button";
import { InvoiceStatus } from "../../../../types/invoice";
import AdminContext from "../../../../state/admin";
import Dropdown from "../../../../components/Form/Dropdown";
import { createOrUpdateInvoice } from "../../../../services/Firebase/invoices";
import { createDraftInvoices } from "../../helpers/invoices";
import { last12Months } from "../../../../state/constants";
import { BulkLineItemFragment, BulkOrderFragment } from "../../../../generated/graphql";
import Switch from "../../../../components/Switch";
import { CurrencyCode } from "../../../../generated/storefront";
import { getAmountsInLocalCurrencies, getPaypalBalances } from "../../../../services/API/paypal";

export type Row = {
  id: string;
  artist: string;
  paypalEmail: string;
  totalOrders: number;
  totalSalePrice: string;
  totalDiscountedPrice: string;
  totalDiscountedAmount: number;
  commission: string;
  commissionAmount: number;
  currency?: CurrencyCode;
  vatNumber: string;
};

type DateSelector = "month" | "range";

const Payments = () => {
  const [dateRange, setDateRange] = useState<DateRange<Date>>([null, null]);
  const [rows, setRows] = useState<Row[]>([]);
  const [loadingInvoices, setLoadingInvoices] = useState(false);
  const [dateSelector, setDateSelector] = useState<DateSelector>("month");
  const [balances, setBalances] = useState<{ currency: CurrencyCode; total: string }[]>();
  const [currencyTotals, setCurrencyTotals] = useState<{ currency: CurrencyCode; gbpAmount: number; localAmount: number }[]>();
  const { month, setMonth, getOrders, monthlyOrders, loadingMonthsOrders } = useContext(AdminContext);
  const totalDiscountedPrice = rows.reduce((total, row) => total + row.totalDiscountedAmount, 0);
  const totalCommission = rows.reduce((total, row) => total + row.commissionAmount, 0);

  const columns: GridColDef[] = [
    { field: "id", headerName: "Artist ID", minWidth: 200 },
    { field: "artist", headerName: "Artist", minWidth: 200 },
    { field: "paypalEmail", headerName: "Paypal Email", minWidth: 250 },
    { field: "totalOrders", headerName: "Total Orders", minWidth: 100, align: "center" },
    { field: "totalSalePrice", headerName: "Total Sale Price", minWidth: 120, align: "right" },
    { field: "totalDiscountedPrice", headerName: "Total Discounted Price", minWidth: 160, align: "right" },
    { field: "commission", headerName: "Commission", minWidth: 100, align: "right" },
    { field: "currency", headerName: "Currency", minWidth: 100, align: "center" },
    { field: "vatRegistered", headerName: "VAT/GST", minWidth: 80, align: "center" },
    {
      field: "generateInvoice",
      headerName: "Invoice",
      minWidth: 150,
      renderCell: (params) => (
        <Button
          size="small"
          onClick={() =>
            createOrUpdateInvoice({
              artist: params.row.artist,
              artistId: params.row.id,
              paypalEmail: params.row.paypalEmail,
              amount: params.row.commissionAmount,
              vatNumber: params.row.vatNumber,
              date: new Date(month),
              status: InvoiceStatus.DRAFT,
            })
          }
        >
          Create draft
        </Button>
      ),
    },
  ];

  const fetchPaypalBalances = async () => {
    const { balances } = await getPaypalBalances();
    setBalances(balances.map((b) => ({ currency: b.currency, total: b.total_balance.value })));
  };

  const getRows = async (
    ordersByVendor: Dictionary<{
      items: (BulkLineItemFragment & { orderCreatedAt: string })[];
    }>
  ) => {
    const rowPromises = Object.entries(ordersByVendor).map(async ([vendorId, orders]) => {
      const { items } = orders;
      const artist = vendorId ? await getUserById(vendorId) : null;
      if (!artist) {
        console.log("No artist found for order", orders);
      }
      const { orderTotal, discountTotal, commissionTotal } = getOrderItemTotals(items);
      const discountedTotal = orderTotal - discountTotal;
      return {
        id: vendorId,
        artist: artist ? `${artist.firstName} ${artist.lastName}` : "GoodMood",
        paypalEmail: artist ? `${artist.paypalEmail}` : "GoodMood",
        totalOrders: items.reduce((total, item) => total + item.quantity, 0),
        totalSalePrice: formattedPrice(orderTotal, CurrencyCode.Gbp),
        totalDiscountedPrice: formattedPrice(discountedTotal, CurrencyCode.Gbp),
        totalDiscountedAmount: discountedTotal,
        commission: formattedPrice(commissionTotal, CurrencyCode.Gbp),
        commissionAmount: commissionTotal,
        currency: artist?.currency,
        vatRegistered: artist && Boolean(artist.vatRegistered?.vatNumber) ? "✅" : "❌",
        vatNumber: artist?.vatRegistered?.vatNumber || "",
      };
    });

    const rowsToBeSet = await Promise.all(rowPromises);
    setRows(sortBy(rowsToBeSet, (r) => -r.commissionAmount));
  };

  const getOrderItems = async (ordersForMonth: BulkOrderFragment[]) => {
    const ordersByVendor = ordersForMonth.reduce(
      (
        result: Dictionary<{
          items: (BulkLineItemFragment & { orderCreatedAt: string })[];
        }>,
        order
      ) => {
        order.lineItems.nodes.forEach((item) => {
          if (result[item.vendor]) {
            result[item.vendor].items = [...result[item.vendor].items, { ...item, orderCreatedAt: order.createdAt }];
          } else {
            result[item.vendor] = {
              items: [{ ...item, orderCreatedAt: order.createdAt }],
            };
          }
        });
        return result;
      },
      {}
    );
    getRows(ordersByVendor);
  };

  const getTotalsForEachCurrency = async (rows: Row[]) => {
    const totals: Dictionary<number> = {};
    rows.forEach((row) => {
      const currency = row.currency || CurrencyCode.Gbp;
      if (totals[currency]) {
        totals[currency] += row.commissionAmount;
      } else {
        totals[currency] = row.commissionAmount;
      }
    });

    const totalsArray = Object.entries(totals).map(([currency, amount]) => ({
      currency: currency as CurrencyCode,
      amount,
    }));

    const totalsInCurrency = await getAmountsInLocalCurrencies(totalsArray);
    setCurrencyTotals(totalsInCurrency);
  };

  const getUsableBalances = () => {
    return (
      balances?.map((b) => {
        const balance = Number(b.total);
        const currency = b.currency;
        const totalInCurrency = currencyTotals?.find((c) => c.currency === currency);

        if (currency !== CurrencyCode.Gbp && totalInCurrency?.localAmount && totalInCurrency.localAmount <= balance) {
          return {
            currency,
            amount: totalInCurrency.localAmount,
            amountInGbp: totalInCurrency.gbpAmount,
          };
        }

        return {
          currency,
          amount: 0,
          amountInGbp: 0,
        };
      }) || []
    );
  };

  const calculateGbpAmountToTransferToPaypal = () => {
    const usableBalances = getUsableBalances();
    const gbpBalance = Number(balances?.find((b) => b.currency === CurrencyCode.Gbp)?.total || 0);
    const usableBalancesTotalInGbp = usableBalances.reduce((total, balance) => total + balance.amountInGbp, 0);
    return Math.max(totalCommission - gbpBalance - usableBalancesTotalInGbp, 0);
  };

  const createInvoices = async () => {
    setLoadingInvoices(true);
    await createDraftInvoices(rows, month);
    setLoadingInvoices(false);
  };

  const handleDateRangeClose = (range: DateRange<Date>) => {
    if (!range[0] || !range[1]) return;
    const startDate = dayjs(range[0]).format("YYYY-MM-DD");
    const endDate = dayjs(range[1]).format("YYYY-MM-DD");
    getOrders(startDate, endDate);
  };

  useEffect(() => {
    if (rows.length) {
      getTotalsForEachCurrency(rows);
    }
  }, [rows]);

  useEffect(() => {
    if (!balances) {
      fetchPaypalBalances();
    }
  }, [balances]);

  useEffect(() => {
    if (monthlyOrders) {
      setRows([]);
      getOrderItems(monthlyOrders);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthlyOrders]);

  useEffect(() => {
    if (!month) return;
    const monthDate = new Date(month);
    setDateSelector("month");
    const startDate = format(startOfMonth(monthDate), "yyyy-MM-dd");
    const endDate = format(endOfMonth(monthDate), "yyyy-MM-dd");
    getOrders(startDate, endDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month]);

  return (
    <Box sx={{ width: "100%" }} paddingY={3}>
      <Box paddingTop={2} paddingBottom={2}>
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Stack gap={2} direction="row" alignItems="center">
            <Switch
              options={[
                { label: "Month", value: "month" },
                { label: "Range", value: "range" },
              ]}
              selected={dateSelector}
              onChange={(value) => setDateSelector(value as DateSelector)}
            />
            {dateSelector === "month" && (
              <Dropdown
                options={last12Months}
                value={last12Months.find((m) => m.value === month)}
                onChange={({ value }) => setMonth(value)}
                style={{ zIndex: 5 }}
                disabled={loadingMonthsOrders}
              />
            )}

            {dateSelector === "range" && (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateRangePicker
                  value={dateRange}
                  onChange={setDateRange}
                  onAccept={handleDateRangeClose}
                  localeText={{ start: "Start", end: "End" }}
                  disabled={loadingMonthsOrders}
                />
              </LocalizationProvider>
            )}

            <Stack gap={2} direction="row">
              <Stack gap={1}>
                <Typography fontWeight={500}>Total Sales:</Typography>
                <Typography>{formattedPrice(totalDiscountedPrice, CurrencyCode.Gbp)}</Typography>
              </Stack>
              <Stack gap={1}>
                <Typography fontWeight={500}>Total Commission:</Typography>
                <Typography>{formattedPrice(totalCommission, CurrencyCode.Gbp)}</Typography>
              </Stack>
              <Stack gap={1}>
                <Typography fontWeight={500}>Paypal Balances:</Typography>
                {balances?.map(
                  (b) =>
                    Number(b.total) > 0 && (
                      <Typography key={b.currency}>
                        {b.currency}: {b.total}
                      </Typography>
                    )
                )}
              </Stack>
              <Stack gap={1}>
                <Typography fontWeight={500}>Amount to transfer:</Typography>
                <Typography>{formattedPrice(calculateGbpAmountToTransferToPaypal(), CurrencyCode.Gbp)}</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Button size="medium" loading={loadingInvoices} onClick={createInvoices}>
            Create Invoices
          </Button>
        </Stack>
      </Box>
      <DataGrid
        style={{ height: loadingMonthsOrders ? "70vh" : "auto", minHeight: "70vh" }}
        loading={loadingMonthsOrders}
        rows={rows}
        columns={columns}
        hideFooterPagination
        slots={{ toolbar: GridToolbar }}
      />
    </Box>
  );
};

export default Payments;
