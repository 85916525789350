import axios from "axios";
import { BasketFragment } from "../../generated/storefront";
import { getIdNumber } from "../../helpers/shopify";

export const triggerAddToCartEvent = async ({
  basket,
  addedItemId,
  customerInfo,
}: {
  basket: BasketFragment;
  addedItemId: string;
  customerInfo: {
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
  };
}) => {
  const lineItems = basket.lines.nodes.map((line) => {
    const { product } = line.merchandise;
    const productDiscount = line.discountAllocations[0] ? Number(line.discountAllocations[0].discountedAmount.amount) : 0;
    return {
      merchandiseId: line.merchandise.id,
      productCategories: [],
      productDescription: product.description,
      productDiscount,
      productID: product.id,
      productImageURL: product.images.nodes[0]?.src || "",
      productPrice: Number(line.cost.totalAmount.amount),
      productQuantity: line.quantity,
      productSKU: line.merchandise.sku || "",
      productStrikeThroughPrice: Number(line.cost.subtotalAmount.amount),
      productTitle: product.title,
      productURL: `https://www.goodmoodprints.io/products/${getIdNumber(product.id)}`,
      productVariantID: line.merchandise.id,
      productVariantImageURL: product.images.nodes[0]?.src,
    };
  });

  const addedItem = lineItems.find((line) => line.merchandiseId === addedItemId);

  if (!addedItem) {
    throw new Error("Could not find added item in basket");
  }

  const email = basket.buyerIdentity.email || customerInfo.email;

  if (!email) return;

  try {
    const options = {
      method: "POST",
      headers: {
        "content-type": "application/json",
        "X-API-KEY": process.env.REACT_APP_OMNISEND_API_KEY || "",
      },
      data: {
        origin: "api",
        eventName: "added product to cart",
        eventVersion: "",
        contact: {
          email,
        },
        properties: {
          abandonedCheckoutURL: basket.checkoutUrl,
          cartID: basket.id,
          currency: basket.cost.totalAmount.currencyCode,
          lineItems,
          addedItem,
          value: basket.cost.totalAmount.amount,
        },
      },
    };

    await axios("https://api.omnisend.com/v5/events", options);
  } catch (error) {
    console.log({ error });
    throw error;
  }
};
