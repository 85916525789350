import React, { useState, useRef, useEffect, PropsWithChildren } from "react";
import styled from "styled-components";
import { Stack, Typography } from "@mui/material";
import { TypographyProps } from "@mui/material/Typography";
import TextLink from "../TextLink";

const ExpandableText = styled(Typography)<{ expanded: boolean }>`
  line-height: 1.5;
  max-height: ${({ expanded }) => (expanded ? "none" : "calc(1.5em * 2)")};
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${({ expanded }) => (expanded ? "unset" : "2")};
  -webkit-box-orient: vertical;
`;

const ReadMoreText: React.FC<PropsWithChildren<TypographyProps>> = ({ children, ...props }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const paragraphRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    const checkOverflow = () => {
      const paragraphElement = paragraphRef.current;
      if (paragraphElement) {
        const lineHeight = parseInt(window.getComputedStyle(paragraphElement).lineHeight, 10);
        const maxHeight = lineHeight * 2;
        if (paragraphElement.scrollHeight > maxHeight) {
          setIsTruncated(true);
        } else {
          setIsTruncated(false);
        }
      }
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow);

    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, []);

  return (
    <Stack alignItems="center" justifyContent="center" gap={0.5} width="100%">
      <ExpandableText ref={paragraphRef} expanded={isExpanded} {...props}>
        {children}
      </ExpandableText>
      {isTruncated && !isExpanded && (
        <TextLink onClick={() => setIsExpanded(true)} style={{ fontSize: 14 }}>
          Show more
        </TextLink>
      )}
      {isExpanded && (
        <TextLink onClick={() => setIsExpanded(false)} style={{ fontSize: 14 }}>
          Show less
        </TextLink>
      )}
    </Stack>
  );
};

export default ReadMoreText;
