import styled from "styled-components";
import { Stack, Typography } from "@mui/material";
import { formattedPrice } from "../../helpers/money";
import { Frame } from "../../types/product";
import { CountryCode, SearchProductFragment } from "../../generated/storefront";
import { useAppState } from "../../state";
import { colors } from "../../theme";
import FrameMockUp from "../../components/FrameMockUp";
import Button from "../../components/Button";

export type Props = {
  product: SearchProductFragment;
  artistName: string;
  addToWall: (product: SearchProductFragment) => void;
  indent?: boolean;
};

export const Wrapper = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const Card = styled.div`
  position: relative;
  background-color: ${colors.grey02};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10%;
  flex-grow: 1;

  &:hover > div {
    opacity: 1;
  }
`;

const Overlay = styled.div`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProductCard = ({ product, artistName, indent, addToWall }: Props) => {
  const { selectedCountry } = useAppState();
  const image = product.images.nodes[0];
  const { minVariantPrice } = product.priceRange;
  const showDecimals = selectedCountry === CountryCode.At;

  return (
    <Stack gap={1} height="100%">
      <Wrapper onClick={() => addToWall(product)}>
        <Card>
          {product.images.nodes.length > 0 && (
            <>
              <Overlay>
                <Button secondary>Add</Button>
              </Overlay>
              <FrameMockUp frame={Frame.Unframed} image={image} />
            </>
          )}
        </Card>

        <Stack paddingTop={1} paddingX={indent ? 1.5 : 0}>
          <Typography variant="body2" fontSize={12} textOverflow="ellipsis" overflow="hidden" style={{ whiteSpace: "nowrap" }}>
            {product.title}
          </Typography>
          <Typography variant="body2" fontSize={12} textOverflow="ellipsis" overflow="hidden" style={{ whiteSpace: "nowrap" }}>
            {artistName}
          </Typography>

          <Stack direction="row" gap={{ xs: 0.75 }}>
            <Typography variant="body2" fontSize={12}>
              From
            </Typography>
            <Typography variant="body2" fontSize={12}>
              {formattedPrice(minVariantPrice.amount, minVariantPrice.currencyCode, showDecimals)}
            </Typography>
          </Stack>
        </Stack>
      </Wrapper>
    </Stack>
  );
};

export default ProductCard;
